import React, {PropsWithChildren, ReactElement} from "react";
import css from "./modal.module.scss"

export default function Modal({children, title, show = false, onClose}: PropsWithChildren<any>): ReactElement {
  return (
    <div onMouseDown={e => e.target === e.currentTarget ? onClose() : null}
         className={`${css.Modal} ${show ? css.Show : ''}`}>
      <div className={css.ModalContent}>
        <div className={css.ModalHeader}>
          <span onClick={() => onClose()} className={css.Close}>&times;</span>
          <h2>{title}</h2>
        </div>
        <div className={css.ModalBody}>
          {children}
        </div>
      </div>

    </div>
  )
}