import React, {PropsWithChildren, ReactElement, useEffect, useState} from "react";
import Button from "../../components/button/button";
import {useRouteError} from "react-router";
import {AxiosError} from 'axios';
import {useAuth0} from "@auth0/auth0-react";
import {LoaderPage} from "../../components/loader/loader";

interface ErrorModalProps extends PropsWithChildren {
  reloadButton?: boolean,
  logoutButton?: boolean
}

function ErrorModal({children, reloadButton, logoutButton}: ErrorModalProps) {
  const {logout} = useAuth0();

  const onLogout = () => {
    localStorage.removeItem('accessToken')
    logout({logoutParams: {returnTo: window.location.origin}});
  };

  return (
    <div style={{
      display: "flex", flexDirection: 'row',
      gap: 20, padding: 20, justifyContent: "center", alignItems: "center", height: 'calc(100vh - 40px)',
      fontFamily: "Circular, sans serif", textAlign: 'center'
    }}>
      <div style={{width: 500, backgroundColor: 'white', borderRadius: 12, padding: 20}}>
        {children}
        <br/><br/>
        <div style={{display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center'}}>
          {
            !reloadButton || <Button onClick={() => window.location.reload()}>Reload Page</Button>
          }
          {
            !logoutButton || <Button onClick={onLogout}>Logout</Button>
          }
        </div>
      </div>
    </div>
  )
}

function UnexpectedError({error}: { error: any }) {
  const mailto = `mailto:xxx@treasuryspring.com?subject=Cash Register Error}`

  return (
    <ErrorModal reloadButton={true}>
      <div style={{fontSize: 23, fontWeight: 700, marginBottom: 20}}>
        An Error has Occurred
      </div>

      <div>
        Please try pressing the button below or contacting Treasury Spring support at &nbsp;
        <a href={mailto}>
          tech@treasuryspring.com
        </a>.
        <br/>
        <br/>

        <code>
          {error.toString()}
        </code>
        <br/>
      </div>
    </ErrorModal>
  )
}


function AuthError({error}: { error: any }) {
  const {loginWithRedirect, isAuthenticated, getAccessTokenSilently, isLoading} = useAuth0();
  const [noToken, setNoToken] = useState(true);

  console.log('in auth error component')

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect().catch(console.error);
    } else if (!isLoading && isAuthenticated) {
      // redirect('/');
      window.location.pathname = '/'
    }

  }, [isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently])

  useEffect(() => {
    if (!localStorage.getItem('accessToken')) setNoToken(false);
    else {
      setTimeout(() => {
        if (!localStorage.getItem('accessToken')) setNoToken(false);
      }, 500)
    }
  }, [setNoToken]);

  console.log(noToken)

  if (isLoading || !isAuthenticated || noToken) {
    // Needed because we get isAuthenticated before Auth0 has loaded.
    return <LoaderPage/>;
  }

  return (
    <ErrorModal reloadButton={true} logoutButton={true}>
      <div style={{fontSize: 23, fontWeight: 700, marginBottom: 20}}>
        You are not authorized to access this page.
      </div>

      <div>
        Please re-login or check with your administrator to see if your email has been whitelisted.
        <br/>
      </div>
    </ErrorModal>
  )
}


export default function ErrorPage(): ReactElement {
  const error = useRouteError();
  if (error instanceof AxiosError) {
    if ([401, 403].includes(error.response.status)) {
      return <AuthError error={error}/>
    } else if (error.response.status === 400) {
      return <UnexpectedError error={error.response.data.error}/>
    }
  } else return <UnexpectedError error={error}/>
}