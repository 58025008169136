import {RESTService} from "./rest";
import {Currency, Cell} from "../types/schema";

export class ConfigService extends RESTService {
  async getCurrencies(): Promise<Currency[]> {
    const result = await this.http.get('/config/currencies')
    return await result.data;
  }

  async updateCurrencies(currencies: Currency[]): Promise<void> {
    await this.http.put("/config/currencies", currencies)
  }

  async getCells(): Promise<Cell[]> {
    const result = await this.http.get('/config/cells')
    return result.data;
  }

  async updateCells(cells: Cell[]): Promise<void> {
    await this.http.put("/config/cells", cells)
  }
}