import bluebird from "../../cell-icons-small/bluebird.png";
import azalea from "../../cell-icons-small/azalea.png";
import cassia from "../../cell-icons-small/cassia.png";
import cp from "../../cell-icons-small/cp.png";
import aspen from "../../cell-icons-small/aspen-1.png";
import coral from "../../cell-icons-small/coral.png";
import dahlia from "../../cell-icons-small/dahlia.png";
import equinox from "../../cell-icons-small/equinox.png";
import laurel from "../../cell-icons-small/laurel.png";
import protea from "../../cell-icons-small/protea.png";
import quercia from "../../cell-icons-small/quercia.png";
import redwood from "../../cell-icons-small/redwood.png";
import viola from "../../cell-icons-small/viola.png";
import React from "react";
import {Tooltip} from "@mui/material";

const cellMap = {
  bluebird,
  azalea,
  cassia,
  cp,
  aspen,
  coral,
  dahlia,
  equinox,
  laurel,
  protea,
  quercia,
  redwood,
  viola,
}

export function CellIcon({cellCode}: { cellCode: string }) {
  const width = 18;
  const image = cellMap[cellCode.split('-')[0].toLowerCase()];

  if (!image) return null

  return <Tooltip title={cellCode}>
    <img
      alt={cellCode}
      src={image}
      style={{width: width, borderRadius: 10}}
    />
  </Tooltip>
}