import {useStore} from "../stores/app-store";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {useEffect, useMemo} from "react";
import {AnyEvent} from "../types/schema";
import {PaymentEventsService} from "../services/payments/payment-service";
import {RESTService} from "../services/rest";


const api = new RESTService();


export function usePaymentService(): PaymentEventsService {
  // Ideally, this function output should not change often.

  const isInitialised = useStore((state) => state.isInitialised)
  const setPaymentService = useStore((state) => state.setPaymentService)
  const session_id = useStore((state) => state.session_id)

  // Connect to websocket.
  const {readyState, lastJsonMessage, sendJsonMessage} = useWebSocket(
    `${api.wsUrl}/payments/subscribe`,
    {
      shouldReconnect: (closeEvent) => true,
      queryParams: {
        authorization: api.accessToken,
        session_id: session_id,
      },
    }
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  console.log('ws status:', connectionStatus)
  // Reinitialise payment service only when websocket changes.
  const paymentService = useMemo(
    () => new PaymentEventsService(readyState, sendJsonMessage, session_id),
    [readyState, sendJsonMessage, session_id]
  ); // TODO: maybe dont need this anymore check version

  // Add the payment service to the global state, so it can be read without using context.
  useEffect(() => {
    setPaymentService(paymentService)
  }, [setPaymentService, paymentService]);

  // Handle new events from websocket dynamically by calling a handler from event-handler.ts
  useEffect(() => {
    if (lastJsonMessage !== null) {
      const eventType = lastJsonMessage['event_type'];
      const handler = paymentService.eventHandlersMap[eventType];
      console.log('handling event', eventType)

      if (!handler) {
        console.error(`Unknown event:`, eventType)
      } else if (isInitialised || eventType === "InitialStateEvent") {
        handler(lastJsonMessage as AnyEvent, useStore, paymentService)
      }
    }
  }, [isInitialised, lastJsonMessage, paymentService]);

  // On first connection request current payment state
  useEffect(() => {
    // This if is designed to fire on the first load (not 100% sure it is correct)
    if (readyState === ReadyState.OPEN && lastJsonMessage === null) {
      paymentService.requestInitialState()
    }
  }, [readyState, paymentService, lastJsonMessage]);

  return paymentService
}