import React from "react";
import css from "../statements/statements.module.scss";
import Card from "../../components/card/card";
import {XeroService} from "../../services/xero-service";
import {useLoaderData} from "react-router";
import {XeroUser} from "../../types/schema";
import Chip from "@mui/material/Chip";
import Button from "../../components/button/button";

const api = new XeroService();

export function loader(): Promise<Array<any>> {
  return api.getUsers();
}

function daysUntil(targetDate: string) {
  const remainingMs = new Date(targetDate).getTime() - new Date().getTime()
  return Math.round((remainingMs / (1000 * 3600 * 24)) * 100) / 100;
}

export default function XeroPage() {
  const xeroUsers = useLoaderData() as XeroUser[];

  return (
    <div className={css.Container}>
      <Card title={'Xero Accounts'}>
        <table style={{width: '100%'}}>
          <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th>Scopes</th>
            <th>Final Expiry</th>
          </tr>
          </thead>
          <tbody>
          {
            xeroUsers.map(user => (
              <tr key={user.email} style={{textAlign: 'center'}}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td style={{maxWidth: 400}}>
                  {
                    user.scope.split(' ').map(scope => (
                      <Chip key={scope} label={scope} style={{marginRight: 5, marginTop: 5}}/>
                    ))
                  }
                </td>
                <td>{daysUntil(user.expires_at)} days</td>
              </tr>
            ))
          }
          </tbody>
        </table>
        <div style={{textAlign: 'center', marginTop: 30}}>
          <Button onClick={() => window.location.pathname = '/api/xero/login'}>Connect Account</Button>
        </div>
      </Card>
    </div>
  )
}