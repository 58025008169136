import {RESTService} from "./rest";

export interface Statement {
  statement_name: string;
}

export class StatementService extends RESTService {
  async uploadAndConvert(bank: string, start: string, end: string, files: File[]): Promise<void> {
    if (files.length === 0) return Promise.resolve();

    const formData = files.reduce((formData, file) => {
      formData.append("files", file);
      return formData;
    }, new FormData());

    const qs = new URLSearchParams({start, end});
    const result = await this.http.post(`/statements/${bank}/convert?${qs}`, formData);
    const data = await result.data;

    const link = document.createElement('a');
    link.href = 'data:application/x-zip;base64,' + data.b64_zip;
    link.download = `${bank}_${start}_${end}.zip`;
    link.target = '_blank'
    link.click();

    return Promise.resolve();
  }

  async getStatements(): Promise<Statement[]> {
    const result = await this.http.get('/statements')
    return result.data;
  }
}