import React, {PropsWithChildren, ReactElement, useState} from "react";
import css from "./card.module.scss"

interface CardProps extends PropsWithChildren<any> {
  title?: string;
  defaultExpanded?: boolean;
}

export default function Card({children, title, defaultExpanded = true}: CardProps): ReactElement {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return <div className={css.Card}>
    {
      title && <div className={css.CardTitle} onClick={() => setExpanded(!expanded)}>{title}</div>
    }
    <div className={css.CardContainer}>
      <div className={`${css.CardBody} ${expanded ? '' : css.collapse}`}>
        {children}
      </div>
    </div>
  </div>
}