import React from "react";
import {Button, Input, Label} from "@treasuryspring/spring-ui"

export const defaultPaymentControlState: PaymentControlState = {
  idSearch: "",
  dateSearch: "",
  currencySearch: "",
  cellSearch: "",
  bankSearch: "",
  commentSearch: ""
};

export interface PaymentControlState {
  idSearch: string;
  dateSearch: string;
  currencySearch: string;
  cellSearch: string;
  bankSearch: string;
  commentSearch: string;
}

interface PaymentControlsProps {
  state: PaymentControlState;
  onFilterChange: (state: PaymentControlState) => void;
  onAddPayment: () => void;
  onDownload: () => void;
}

export default function PaymentControls(props: PaymentControlsProps) {
  const {state: controlState, onFilterChange, onAddPayment, onDownload} = props;

  return (
    <div style={{
      display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', gap: 20, marginBottom: 5,
      position: 'fixed', width: 'calc(100% - 65px)', height: 55, backgroundColor: '#FFF', borderRadius: 12, padding: 15,
      top: 66, zIndex: 5
    }}>
      <div>
        <Input
          onChange={e => onFilterChange({...controlState, idSearch: e.target.value})}
          value={controlState.idSearch}
        />
        <Label>Filter by ID</Label>
      </div>

      <div>
        <Input
          onChange={e => onFilterChange({...controlState, dateSearch: e.target.value})}
          value={controlState.dateSearch}
        />
        <Label>Filter by Date</Label>
      </div>

      <div>
        <Input
          onChange={e => onFilterChange({...controlState, currencySearch: e.target.value})}
          value={controlState.currencySearch}
        />
        <Label>Filter by Currency</Label>
      </div>

      <div>
        <Input
          onChange={e => onFilterChange({...controlState, cellSearch: e.target.value})}
          value={controlState.cellSearch}
        />
        <Label>Filter by Cell</Label>
      </div>

      <div>
        <Input
          onChange={e => onFilterChange({...controlState, bankSearch: e.target.value})}
          value={controlState.bankSearch}
        />
        <Label>Filter by Bank</Label>
      </div>

      <div>
        <Input
          onChange={e => onFilterChange({...controlState, commentSearch: e.target.value})}
          value={controlState.commentSearch}
        />
        <Label>Filter by Comment</Label>
      </div>

      <Button variant={'primary'} size={'small'} onClick={onAddPayment}>Add Payment</Button>
      <Button variant={'secondary'} size={'small'} onClick={onDownload}>Download</Button>
    </div>
  )
}