import React, {ReactElement, Fragment, useEffect, useState} from "react";
import SideBar from "./components/sidebar/sidebar";
import {Outlet, useNavigate, useNavigation} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {RESTService} from "./services/rest";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Loader, LoaderPage} from "./components/loader/loader";
import {useStore} from "./stores/app-store";
import {closeSnackbar, SnackbarProvider} from "notistack";
import {datadogRum} from "@datadog/browser-rum";


const api = new RESTService();

const TEST_MODE = false;

export default function App(): ReactElement {
  const navigation = useNavigation();
  const navigate = useNavigate();
  const {loginWithRedirect, isAuthenticated, getAccessTokenSilently, isLoading, user, logout} = useAuth0();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const setUserData = useStore(state => state.setUserData);
  const setErrorState = useStore(state => state.setErrorState);
  const errorState = useStore(state => state.errorState);

  // Add user data when app component mounts.
  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      datadogRum.setUser({
        name: user.name,
        email: user.email,
        organisation: user.email.endsWith("@treasuryspring.com") ? "ts" : "rh"
      });
      setUserData({
        email: user.email,
        name: user.name,
        image: user.picture,
        organisation: user.email.endsWith("@treasuryspring.com") ? "ts" : "rh"
      });
    }
  }, [user, setUserData, isAuthenticated, isLoading]);

  // Get the users token when the component mounts.
  useEffect(() => {
    // TODO: switch to using withAuthRequired https://github.com/auth0-developer-hub/spa_react_javascript_hello-world/tree/basic-authentication
    if (isAuthenticated && !isLoading && !TEST_MODE) {
      getAccessTokenSilently()
        .then(token => {
          localStorage.setItem('accessToken', token)
          return api.checkWhitelist();
        })
        .then(({is_whitelisted}) => {
          if (is_whitelisted) {
            setIsLoggedIn(true);
            navigate('/payments');
          } else return logout({logoutParams: {returnTo: window.location.origin}});
        })
        .catch(err => {
          localStorage.removeItem('accessToken');
          setErrorState(err);
        })
    }
  }, [
    getAccessTokenSilently, isAuthenticated,
    isLoading, navigate, setErrorState, setIsLoggedIn, logout
  ]);

  if (errorState !== null) {
    // This check exists to propagate errors from async components to the react-router error page.
    throw errorState;
  }

  if (isLoading) {
    // Needed because we get isAuthenticated before Auth0 has loaded.
    return <LoaderPage/>;
  }

  if (!isAuthenticated && !TEST_MODE) {
    // If auth0 says the user isn't authenticated, redirect them to log in.
    loginWithRedirect().catch(console.error);
    return <LoaderPage/>;
  }

  if (!isLoggedIn) {
    // Needed because an auth0 can be loaded, user authenticated, but not logged in.
    return <LoaderPage/>;
  }


  return <Fragment>
    <SnackbarProvider autoHideDuration={10000} action={(snackbarId) => (
      <button onClick={() => closeSnackbar(snackbarId)}>
        Dismiss
      </button>
    )}/>
    <div style={{
      height: "calc(100vh - 16px)",
      display: "flex",
      gap: 8,
      alignItems: 'stretch',
      flexDirection: 'column',
      padding: 8
    }}>
      <SideBar/>
      <div style={{
        overflow: "auto",
        backgroundColor: '#F6F7F8',
        flex: 1,
        borderRadius: 10,
        padding: 10,
        fontFamily: "Circular, sans serif"
      }}>
        {navigation.state === "loading" ? <Loader/> : <Outlet/>}
      </div>
    </div>
    <ToastContainer position="bottom-right"/>
  </Fragment>;
}