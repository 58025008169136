import React, {PropsWithChildren, ReactElement, useState} from "react";
import Modal from "../../../components/modal/modal";
import Button from "../../../components/button/button";
// import css from "./accounts.module.scss"

export default function CreateAccountModal({show = false, onClose, onCreate}: PropsWithChildren<any>): ReactElement {
  const [bankName, setBankName] = useState('');
  const [bankCode, setBankCode] = useState('');

  const closeAndReset = () => {
    onClose();
    setBankName('');
    setBankCode('');
  };
  const createAndReset = () => {
    onCreate(bankName, bankCode);
    closeAndReset();
  };

  return (
    <Modal title={'Create Bank'} show={show} onClose={closeAndReset}>
      <span>Enter Bank Name: </span>
      <input value={bankName} onChange={e => setBankName(e.target.value)} type="text"/>
      <br/>
      <span>Enter Bank Code: </span>
      <input value={bankCode} onChange={e => setBankCode(e.target.value)} type="text"/>
      <div style={{textAlign: 'right', marginBottom: 15}}>
        <Button onClick={closeAndReset}>Cancel</Button>
        <Button onClick={() => createAndReset()}>Create</Button>
      </div>
    </Modal>
  )
}