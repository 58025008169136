import React, {ReactElement} from "react";
import {useStore} from "../../../stores/app-store";
import Modal from "../../../components/modal/modal";
import {PaymentTable} from "./tables/basic-payment-table";
import Button from "../../../components/button/button";

interface DeletePaymentModalProps {
  paymentDeletionProposal: number;
  show: boolean;
}

export default function DeletePaymentModal({
                                             paymentDeletionProposal,
                                             show = false
                                           }: DeletePaymentModalProps): ReactElement {
  const clearPaymentDeletionProposal = useStore((state) => state.clearPaymentDeletionProposal)
  const getPaymentById = useStore((state) => state.getPaymentById)
  const paymentService = useStore(state => state.paymentService);

  const closeAndReset = () => {
    clearPaymentDeletionProposal()
  };
  const onDelete = () => {
    paymentService.deletePayment(payment.payment_id)
    clearPaymentDeletionProposal()
  };

  const payment = getPaymentById(paymentDeletionProposal)

  if (!payment) return null;

  return (
    <Modal title={`Delete Payment (${paymentDeletionProposal})`} show={show} onClose={closeAndReset}>
      <PaymentTable paymentState={[payment]} focussedPaymentId={[-1]} hideActions/>
      <div style={{marginBottom: 15, marginTop: 20, display: 'flex', gap: 10, justifyContent: 'flex-end'}}>
        <Button onClick={() => closeAndReset()}>Cancel</Button>
        <Button onClick={onDelete}>
          Delete
        </Button>
      </div>
    </Modal>
  )
}