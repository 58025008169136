import {PaymentEventsService} from "./payment-service";
import {StoreType} from "../../stores/app-store";
import {enqueueSnackbar} from 'notistack';

import {
  AnyEvent, ErrorNotificationEvent,
  InitialStateEvent, NettedPayment,
  NettedPaymentProposalAcknowledgedEvent,
  PaymentAcknowledgedEvent,
  PaymentApprovedEvent,
  PaymentContestedEvent,
  PaymentDeletedEvent,
  PaymentLockedEvent,
  PaymentsCreatedEvent,
  PaymentsUpdatedEvent
} from "../../types/schema";

export type EventHandler = (event: AnyEvent, paymentStore: StoreType, paymentService: PaymentEventsService) => void;

export const initialStateEventHandler: EventHandler =
  function (event: InitialStateEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    paymentStore.getState().initialiseState(event)
  }

export const paymentsCreatedEventHandler: EventHandler =
  function (event: PaymentsCreatedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();
    state.createPayments(event.payments)
  }

export const paymentDeletedEventHandler: EventHandler =
  function (event: PaymentDeletedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();
    state.deletePayment(event.payment_id)
  }

export const nettedPaymentDeletedEventHandler: EventHandler =
  function (event: PaymentDeletedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();
    const nettedPayment = state.payments.find(p => p.payment_id === event.payment_id) as NettedPayment;
    state.deletePayment(event.payment_id)
    nettedPayment.single_payment_ids.forEach(payment_id => state.deletePayment(payment_id))
  }

export const paymentLockedEventHandler: EventHandler =
  function (event: PaymentLockedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();

    if (event.locking_user_email === state.userData.email && event.locking_session_id === state.session_id) {
      // TODO: this function could probably work by passing the whole array of IDs
      state.openEditPayment(event.payment_ids[0])
    } else {
      // If the current user didn't require the lock, just update the payment state.
      state.lockPayments(event.payment_ids, {
        user_email: event.locking_user_email,
        session_id: event.locking_session_id,
      })
    }
  }
export const paymentUnlockedEventHandler: EventHandler =
  function (event: PaymentLockedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();

    // If this client requested the unlocking, close their edit window.
    if (event.locking_user_email === state.userData.email && event.locking_session_id === state.session_id) {
      state.closeEditPayment()
    }

    // Update local payment state
    state.unlockPayments(event.payment_ids, {
      user_email: event.locking_user_email,
      session_id: event.locking_session_id,
    })
  }

export const paymentsUpdatedEventHandler: EventHandler =
  function (event: PaymentsUpdatedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();

    // Update local payment state
    state.updatePayments(event.payments)
  }

export const paymentApprovedEventHandler: EventHandler =
  function (event: PaymentApprovedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();
    state.updatePaymentStage(event.payment_id, event.stage, event.user.user_email);
  }

export const paymentAcknowledgedEventHandler: EventHandler =
  function (event: PaymentAcknowledgedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();
    state.updatePaymentStage(event.payment_id, event.stage, event.user.user_email);

    enqueueSnackbar(`Payment ${event.payment_id} Acknowledged by ${event.user.user_email}`, {variant: 'success'});
  }

export const paymentRHConfirmedEventHandler: EventHandler =
  function (event: PaymentAcknowledgedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();
    const payment = state.payments.find(p => p.payment_id === event.payment_id);
    state.updatePaymentStage(event.payment_id, event.stage, event.user.user_email);

    // When a netted payment is progressed, its constituents should also.
    if (payment.payment_type === 'NETTED') {
      for (const singlePaymentId of payment.single_payment_ids) {
        const single = state.payments.find(p => p.payment_id === singlePaymentId);
        state.updatePaymentStage(single.payment_id, event.stage, event.user.user_email);
      }
    }
  }

export const paymentReleasedEventHandler: EventHandler =
  function (event: PaymentAcknowledgedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();
    const payment = state.payments.find(p => p.payment_id === event.payment_id);
    state.updatePaymentStage(event.payment_id, event.stage, event.user.user_email);

    // When a netted payment is progressed, its constituents should also.
    if (payment.payment_type === 'NETTED') {
      for (const singlePaymentId of payment.single_payment_ids) {
        const single = state.payments.find(p => p.payment_id === singlePaymentId);
        state.updatePaymentStage(single.payment_id, event.stage, event.user.user_email);
      }
    }
  }

export const paymentTSConfirmedEventHandler: EventHandler =
  function (event: PaymentAcknowledgedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();
    const payment = state.payments.find(p => p.payment_id === event.payment_id);
    state.updatePaymentStage(event.payment_id, event.stage, event.user.user_email);

    // When a netted payment is progressed, its constituents should also.
    if (payment.payment_type === 'NETTED') {
      for (const singlePaymentId of payment.single_payment_ids) {
        const single = state.payments.find(p => p.payment_id === singlePaymentId);
        state.updatePaymentStage(single.payment_id, event.stage, event.user.user_email);
      }
    }
  }

export const paymentContestedEventHandler: EventHandler =
  function (event: PaymentContestedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();
    const payment = state.payments.find(p => p.payment_id === event.payment_id);

    // User email is null as contest undoes previous.
    state.updatePaymentStage(event.payment_id, "RH_ACKNOWLEDGE", null);
    state.updatePaymentStage(event.payment_id, "SECOND_APPROVAL");

    // When a netted payment is progressed, its constituents should also.
    if (payment.payment_type === 'NETTED') {
      for (const singlePaymentId of payment.single_payment_ids) {
        const single = state.payments.find(p => p.payment_id === singlePaymentId);
        state.updatePaymentStage(single.payment_id, "RH_ACKNOWLEDGE", null);
        state.updatePaymentStage(single.payment_id, "SECOND_APPROVAL");
      }
    }

    enqueueSnackbar(`Payment ${event.payment_id} Contested by ${event.user.user_email}`, {variant: 'error'});
  }

export const nettedPaymentProposalAcknowledgedEventHandler: EventHandler =
  function (event: NettedPaymentProposalAcknowledgedEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    const state = paymentStore.getState();
    state.createPayments([event.netted_payment])
    event.netted_payment.single_payment_ids.forEach(p_id =>
      state.updatePaymentStage(p_id, event.netted_payment.stage, event.user.user_email))
  }

export const errorNotificationEventHandler: EventHandler =
  function (event: ErrorNotificationEvent, paymentStore: StoreType, paymentService: PaymentEventsService) {
    enqueueSnackbar(event.message, {variant: 'error'});
  }