import React, {ReactElement} from "react";
import css from "./sidebar.module.scss";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import {Avatar, Tooltip} from "@mui/material";
import Button from "../button/button";

function TabButton({text, path, selected = false}: { text: string, path: string, selected?: boolean }) {
  const navigate = useNavigate();
  return (
    <div className={selected ? css.TabButtonSelected : css.TabButton} onClick={() => navigate(path)}>
      {text}
    </div>
  )
}

interface NavTabType {
  path: string;
  title: string;
}

function TabGroup({tabs}: { tabs: NavTabType[] }) {
  return (
    <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
      {
        tabs.map(({title, path}) => (
          <TabButton key={path} text={title} path={path} selected={window.location.pathname === path}/>
        ))
      }
    </div>
  )
}

export default function SideBar(): ReactElement {
  const {logout, user} = useAuth0();
  const isRH = !user || !user.email.endsWith("@treasuryspring.com");

  const navTabs: NavTabType[] = isRH ? [{path: '/payments', title: 'Payments'}] : [
    {path: '/payments', title: 'Payments'},
    {path: '/accounts', title: 'Accounts'},
    {path: '/statements', title: 'Statements'},
    {path: '/config', title: 'Configuration'},
    {path: '/xero', title: 'Xero'}
  ]

  const onLogout = () => {
    localStorage.removeItem('accessToken')
    logout({logoutParams: {returnTo: window.location.origin}});
  };

  return <div className={css.SideBar}>
    <img src={'/logo.svg'} style={{width: 100}} alt={'Treasury Spring Logo'}/>
    <TabGroup tabs={navTabs}/>

    <div style={{display: 'flex', height: 40, gap: 10}}>
      {
        !user ? null : (
          <Tooltip title={<span>{user.name} <br/> {user.email}</span>}>
            <Avatar sx={{width: 38, height: 38}} src={user.picture}/>
          </Tooltip>
        )
      }
      <Button onClick={onLogout}>
        Logout
      </Button>
    </div>
  </div>
}
