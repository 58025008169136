import React, {ReactElement, useState} from "react";
import Card from "../../components/card/card"
// import {useLoaderData} from "react-router-dom";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import {FileUploader} from "react-drag-drop-files";
import {toast} from 'react-toastify';
import css from "./statements.module.scss";
import Button from "../../components/button/button";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import {StatementService} from "../../services/statement-service";
// This date picker is nice, but we have to use a fork to support react 17+.
// Original docs here https://kiarash-z.github.io/react-modern-calendar-datepicker/docs

const api = new StatementService();

export function loader(): Promise<Array<any>> {
  return api.getStatements();
}

function objToDate(obj: any): string {
  return `${obj.year}-${String(obj.month).padStart(2, '0')}-${String(obj.day).padStart(2, '0')}`
}

function Uploader({statementSource, fileTypes, multiple, onUpload}: {
  statementSource: string,
  fileTypes: string[],
  multiple: boolean,
  onUpload: CallableFunction
}): ReactElement {
  const [file, setFile] = useState(null);
  const handleChange = (file: any) => {
    // This function handles both single files and lists of files, hence the following code.
    let fileList;
    if (file.length === undefined) fileList = [file]
    else fileList = [...file]

    setFile(fileList);
    onUpload(fileList);
  };

  const handleReset = () => {
    setFile(null);
    onUpload([]);
  }

  return <div style={{paddingTop: 20}}>
    <div style={{fontSize: 14, fontWeight: 700, marginBottom: 20}}>Upload {statementSource}</div>
    {file ? <div>
      {
        file.map((f: File, i: number) => <p key={i}>{f.name}</p>)
      }
      <Button onClick={handleReset}>Reset</Button>
    </div> : <FileUploader
      multiple={multiple}
      handleChange={handleChange}
      name="file"
      types={fileTypes}
    />}
  </div>
}


export default function StatementsPage(): ReactElement {
  // const initialEntities = useLoaderData() as Statement[];
  const [bnycFiles, setBnycFiles] = useState([]);
  const [bnysFiles, setBnysFiles] = useState([]);
  const [cblFiles, setCblFiles] = useState([]);
  const [rbsiFiles, setRbsiFiles] = useState([]);
  const [eurFiles, setEurFiles] = useState([]);
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });

  const isAnythingUploaded = (bnycFiles.length + bnysFiles.length + cblFiles.length + rbsiFiles.length) > 0
  const isDateSelected = !(selectedDayRange.to === null || selectedDayRange.from === null);
  const convert = async (bank: string, files: File[]) => {
    if (files.length === 0) return;

    const start = objToDate(selectedDayRange.from);
    const end = objToDate(selectedDayRange.to);

    return await toast.promise(
      api.uploadAndConvert(bank, start, end, files),
      {
        pending: {
          render: () => `Converting Statements: ${bank}`
        },
        success: {
          render: () => `Statements Converted: ${bank}`
        },
        error: {
          autoClose: false,
          render: ({data}) => `${data}`
        }
      })
      .catch(err => console.error(err))
  }

  const onClickConvert = async () => {
    await Promise.all([
      convert('bnyc', bnycFiles),
      convert('bnys', bnysFiles),
      convert('cbl', cblFiles),
      convert('rbsi', rbsiFiles),
      convert('eur', eurFiles),
    ])
  };

  return <div className={css.Container}>
    <Card title={'Upload Statements'}>
      <Uploader statementSource={'BNYC'} fileTypes={['xls']} multiple={true} onUpload={setBnycFiles}/>
      <Uploader statementSource={'BNYS'} fileTypes={['csv']} multiple={true} onUpload={setBnysFiles}/>
      <Uploader statementSource={'CBL'} fileTypes={['xlsx']} multiple={false} onUpload={setCblFiles}/>
      <Uploader statementSource={'RBSI'} fileTypes={['csv']} multiple={false} onUpload={setRbsiFiles}/>
      <Uploader statementSource={'EUR'} fileTypes={['txt']} multiple={true} onUpload={setEurFiles}/>
      <hr/>
      <DatePicker
        inputClassName={css.DatePickerInput}
        value={selectedDayRange}
        onChange={setSelectedDayRange}
        inputPlaceholder="Time Period"
      />
      <br/>
      <Button onClick={onClickConvert}
              disabled={!isAnythingUploaded || !isDateSelected}>
        Convert and Download
      </Button>
    </Card>
  </div>
}