import {RESTService} from "./rest";
import {XeroUser} from "../types/schema";

export interface Statement {
  statement_name: string;
}

export class XeroService extends RESTService {
  async getUsers(): Promise<XeroUser[]> {
    const result = await this.http.get('/xero/users')
    return result.data;
  }
}