import React, {ReactElement, useState} from "react";
import css from "./payment-table.module.scss"
import Button from "../../../../components/button/button";
import {Tooltip} from "@mui/material";
import {NettedPayment, NettedPaymentProposal, Payment, Transfer, UserSession} from "../../../../types/schema";
import {PaymentActionMenu} from "../payment-action-menu";
import {
  AnyPayment,
  findNonContingentPayments,
  groupByContingency,
  NettedPaymentProposalWithContingency,
  NettedPaymentWithContingency,
  PaymentOptions,
  PaymentWithContingency,
  stageUserPropertyMap,
  useStore
} from "../../../../stores/app-store";
import LockIcon from '@mui/icons-material/Lock';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Modal from "../../../../components/modal/modal";
import CurrencyFlag from "../../../../components/currency-flag/currency-flag";
import {Badge} from "@treasuryspring/spring-ui";
import {CellIcon} from "../../../../components/cell-icon/cell-icon";

export function DateBadge({date}: { date: string }) {
  const isPaymentFuture = isFuture(date);
  return (
    <Badge style={{fontSize: 14}} variant={isPaymentFuture ? 'error' : 'success'}>
      <Tooltip title={isPaymentFuture ? 'Future' : 'Today'}><span>{date}</span></Tooltip>
    </Badge>
  )
}

export function MiniStepper({payment}: { payment: AnyPayment }): ReactElement {
  const paymentStage = useStore(state => state.paymentOptions.stages)
  const stageIndex = paymentStage.findIndex(s => s === payment.stage);

  const tooltipTitle = (stage: string) => {
    const propertyKey = stageUserPropertyMap[stage];
    if (propertyKey) return (
      <div style={{textAlign: 'center'}}>
        {stage}
        <br/>
        {payment[propertyKey]}
      </div>
    );
    else return stage;
  };

  let stageColour: string;
  if (stageIndex < 3) stageColour = "#ffd058";
  else if (stageIndex === 3) stageColour = "#ff752a";
  else if (stageIndex === 4) stageColour = "#4bb543";
  else stageColour = "#1c62e8";

  return (
    <div className={css.StepperWrapper}>
      {
        paymentStage.map((stage, i) => (
          <div key={stage} className={css.StepperItem}>
            <Tooltip title={tooltipTitle(stage)}>
              <div
                className={css.StepCounter}
                style={i <= stageIndex ? {backgroundColor: stageColour} : {}}
              />
            </Tooltip>
          </div>
        ))
      }
    </div>
  )
}

export interface CondensedPaymentType {
  payment_id: number[][];
  date: string;
  amount: { currency: string, value: number }[][];
  transfer: string[][],
  comment: string;
  stage: string[][];
}


export function encodeNumber(value: number) {
  return Number(value)
    .toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

export function isFuture(date: string) {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  const d = new Date(date);
  d.setHours(0, 0, 0, 0)
  return d > now
}


export function condensePayments(payments: Payment[]) {
  const independentPayments = findNonContingentPayments(payments);
  const grouped = independentPayments.map(p =>
    groupByContingency(p, payments, []).reverse());
  const condensed: CondensedPaymentType[] = grouped.map((group) => {
    const transfer = group.reduce((acc: string[][], stage, i) => {
      if (i === 0) {
        acc.push(stage.map(p => p.transfer.source.bank));
        acc.push([stage[0].transfer.destination.bank]); // Only a single 'destination' must exist as staged payments can only pay the same account.
      } else if (i === group.length - 1) {
        acc.push([stage[stage.length - 1].transfer.destination.bank])
      } else acc.push(stage.map(p => p.transfer.source.bank));

      return acc;
    }, []);

    const payment_id = group.map(stage => stage.map(p => p.payment_id));
    const stage = group.map(stage => stage.map(p => p.stage));
    const amount = group.map(stage => stage.map(p => p.amount));

    return {
      payment_id,
      date: group[0][0].date,
      amount,
      transfer,
      comment: group[0][0].comment,
      stage
    };
  })

  return condensed
}

function getNettingKey(payment: Payment, paymentOptions: PaymentOptions) {
  // If you want to change how netting works, you probably want to change this function, not 'netPayments'.
  let nettingKey: string[];
  const destinationBank = paymentOptions.banks.find(b => b.bank_code === payment.transfer.destination.bank) || {is_icc: false};
  const sourceBank = paymentOptions.banks.find(b => b.bank_code === payment.transfer.source.bank) || {is_icc: false};

  if (destinationBank.is_icc && sourceBank.is_icc) {
    // Both source and destination are ICC so cells are irrelevant.
    nettingKey = [
      payment.date,
      payment.amount.currency,
      `${payment.transfer.source.bank}-source`,
      `${payment.transfer.destination.bank}-destination`
    ]
  } else if (destinationBank.is_icc) {
    // Only destination is ICC so destination cell is irrelevant.
    nettingKey = [
      payment.date,
      payment.amount.currency,
      `${payment.transfer.source.bank}-${payment.transfer.source.cell}`,
      `${payment.transfer.destination.bank}-destination` // '-destination' is added in place of cell to make distinct from source.
    ]
  } else if (sourceBank.is_icc) {
    // Only source is ICC, so source cell is irrelevant.
    nettingKey = [
      payment.date,
      payment.amount.currency,
      `${payment.transfer.source.bank}-source`, // '-source' used in place of cell name
      `${payment.transfer.destination.bank}-${payment.transfer.destination.cell}`
    ]
  } else {
    // Cell for both source and destination is relevant.
    nettingKey = [
      payment.date,
      payment.amount.currency,
      `${payment.transfer.source.bank}-${payment.transfer.source.cell}`,
      `${payment.transfer.destination.bank}-${payment.transfer.destination.cell}`
    ]
  }
  return nettingKey.sort().join('.');
}

function getDirectionalNettingKey(payment: Payment, paymentOptions: PaymentOptions) {
  const sourceBank = paymentOptions.banks.find(b => b.bank_code === payment.transfer.source.bank) || {is_icc: false};

  if (sourceBank.is_icc) {
    return `${payment.transfer.source.bank}-source`
  } else {
    return `${payment.transfer.source.bank}-${payment.transfer.source.cell}`
  }
}


export function netPayments(payments: Payment[], paymentOptions: PaymentOptions) {
  // You probably don't want to edit this function, see getNettingKey for netting rules.
  // First group payments based on currency, bank and cell. Disregard the direction.
  const nettingMap = payments.reduce((acc: any, payment, i) => {
    // Hack to simulate set comparison (which sucks in javascript)
    // Key defines the bucket the payments will fall into, payments with the same key are netted.
    const key = getNettingKey(payment, paymentOptions);

    if (!payment.allow_netting) acc[i] = [payment]; // Payments with netting disallowed should never be grouped.
    else if (acc[key]) acc[key].push(payment);
    else acc[key] = [payment];
    return acc;
  }, {})

  // Transform the grouped payments into netted payments for display.
  return Object.values(nettingMap).map((nettedPayments: Payment[]) => {
    if (nettedPayments.length === 1) return nettedPayments[0]; // Doesn't require netting.

    // Subgroup each group based on the direction.
    const subGroups: Payment[][] = Object.values(nettedPayments.reduce((acc: any, payment: Payment) => {
      const key = getDirectionalNettingKey(payment, paymentOptions);
      if (acc[key]) acc[key].push(payment);
      else acc[key] = [payment];
      return acc;
    }, {}))

    // A single subgroup indicates all netted payments are in the same direction and can be summed.
    if (subGroups.length === 1) {
      const netted: NettedPaymentProposal = {
        payment_ids: subGroups[0].map(p => p.payment_id),
        payment_type: "NETTED_PROPOSAL",
        stage: subGroups[0][0].stage,
        date: subGroups[0][0].date,
        transfer: subGroups[0][0].transfer,
        locked_by: subGroups[0].reduce((acc: UserSession, p) => acc || p.locked_by, null),
        amount: {
          value: subGroups[0].reduce((acc, p) => acc + p.amount.value, 0),
          currency: subGroups[0][0].amount.currency
        },
        dependent_payment_ids: subGroups[0].map(p => p.dependent_payment_ids || []).flat(),
        payments: subGroups[0]
      };

      return netted;
    }

    // Two subgroups indicate that bidirectional netting is needed, calculate which direction.
    const totalAmounts = subGroups.map(subGroup => subGroup.reduce((acc: number, p) => acc + p.amount.value, 0))
    const destinationIndex = totalAmounts.indexOf(Math.min(...totalAmounts))
    const sourceIndex = totalAmounts.indexOf(Math.max(...totalAmounts))
    totalAmounts[destinationIndex] *= -1
    const nettedAmount = totalAmounts[0] + totalAmounts[1];

    const netted: NettedPaymentProposal = {
      payment_ids: subGroups.map(g => g.map(p => p.payment_id)).flat(),
      payment_type: "NETTED_PROPOSAL",
      date: subGroups[0][0].date,
      stage: subGroups[0][0].stage,
      transfer: subGroups[sourceIndex][0].transfer,
      locked_by: subGroups[0].reduce((acc: UserSession, p) => acc || p.locked_by, null),
      amount: {
        value: nettedAmount,
        currency: subGroups[0][0].amount.currency
      },
      dependent_payment_ids: subGroups.map(g => g.map(p => p.dependent_payment_ids || [])).flat(2),
      payments: nettedPayments
    };

    return netted;
  });
}

const arrayEqual = (a: any[], b: any[]) =>
  a.length === b.length && a.every((element: any, index: number) => element === b[index]);

interface PaymentTableProps {
  paymentState: (Payment | NettedPayment | NettedPaymentProposal)[];
  hideActions?: boolean;
  focussedPaymentId?: number[] | null;
}

export function PaymentTable({paymentState, hideActions = false, focussedPaymentId}: PaymentTableProps): ReactElement {
  const addContingencyProperty = useStore(state => state.addContingencyProperty)
  const paymentStage = useStore(state => state.paymentOptions.stages)
  const [sortCol, setSortCol] = useState('payment_id');
  const [sortDirection, setSortDirection] = useState(1);

  const onColClick = (column: string) => {
    setSortCol(column);
    if (sortDirection === -1) setSortDirection(1);
    else if (sortDirection === 1) setSortDirection(-1);
  }

  const paymentRows: AnyPaymentWithContingencyAndHideActions[] = addContingencyProperty(paymentState).map(payment => ({
    ...payment,
    hideActions
  })).sort((a, b) => {
    switch (sortCol) {
      case "payment_id":
        if ((a as Payment).payment_id > (b as Payment).payment_id) return sortDirection
        if ((a as Payment).payment_id < (b as Payment).payment_id) return -sortDirection
        else return 0
      case "date":
        if ((a as Payment).date > (b as Payment).date) return sortDirection
        if ((a as Payment).date < (b as Payment).date) return -sortDirection
        else return 0
      case "currency":
        if ((a as Payment).amount.currency > (b as Payment).amount.currency) return sortDirection
        if ((a as Payment).amount.currency < (b as Payment).amount.currency) return -sortDirection
        else return 0
      case "amount":
        if ((a as Payment).amount.value > (b as Payment).amount.value) return sortDirection
        if ((a as Payment).amount.value < (b as Payment).amount.value) return -sortDirection
        else return 0
      case "transfer":
        if ((a as Payment).transfer.source.cell > (b as Payment).transfer.source.cell) return sortDirection
        if ((a as Payment).transfer.source.cell < (b as Payment).transfer.source.cell) return -sortDirection
        else return 0
      case "comment":
        if ((a as Payment).comment > (b as Payment).comment) return sortDirection
        if ((a as Payment).comment < (b as Payment).comment) return -sortDirection
        else return 0
      case "stage":
        const stageIndexA = paymentStage.findIndex(s => s === a.stage);
        const stageIndexB = paymentStage.findIndex(s => s === b.stage);

        if (stageIndexA > stageIndexB) return sortDirection
        if (stageIndexA < stageIndexB) return -sortDirection
        else return 0
    }

    return 0
  })

  const columns = {
    payment_id: 'ID',
    date: 'Date',
    currency: 'Currency',
    amount: 'Amount',
    transfer: 'Transfer',
    comment: 'Comment',
    stage: 'Stage',
  }

  return (
    <table style={{width: '100%', borderCollapse: 'collapse'}}>
      <thead>
      <tr>
        <th></th>
        {
          Object.keys(columns).map(colId => {
            const sortIcon = sortDirection === -1 ? <span>&#9650;</span> : <span>&#9660;</span>
            return <th key={colId}
                       style={{cursor: 'pointer'}}
                       onClick={() => onColClick(colId)}>{columns[colId]} {sortCol === colId ? sortIcon : ""}</th>
          })
        }
        {
          hideActions || <th>Action</th>
        }
      </tr>
      </thead>
      <tbody>
      {
        paymentRows.map((row, i) => {
          if (row.payment_type === 'SINGLE') {
            return (
              <PaymentRow
                key={row.payment_id}
                focus={focussedPaymentId && focussedPaymentId[0] === row.payment_id}
                {...row}
              />
            );
          } else if (row.payment_type === 'NETTED_PROPOSAL') {
            const key = (row as NettedPaymentProposal).payment_ids.join(',')
            return <NettedPaymentProposalRow
              key={key}
              focus={focussedPaymentId && arrayEqual(row.payment_ids, focussedPaymentId)}
              {...row as NettedPaymentProposalWithContingencyAndUI}
            />
          } else {
            return <NettedPaymentRow
              key={row.payment_id}
              focus={focussedPaymentId && focussedPaymentId[0] === row.payment_id}
              {...row as NettedPaymentWithContingencyAndUI}
            />
          }
        })
      }
      </tbody>
    </table>
  )
}


export interface PaymentWithContingencyAndUI extends PaymentWithContingency {
  hideActions: boolean;
  focus?: boolean;
}

export interface NettedPaymentWithContingencyAndUI extends NettedPaymentWithContingency {
  hideActions: boolean;
  focus?: boolean;
}

export interface NettedPaymentProposalWithContingencyAndUI extends NettedPaymentProposalWithContingency {
  hideActions: boolean;
  focus?: boolean;
}

export type AnyPaymentWithContingencyAndHideActions =
  PaymentWithContingencyAndUI
  | NettedPaymentWithContingencyAndUI
  | NettedPaymentProposalWithContingencyAndUI


export function TransferCell({transfer}: { transfer: Transfer }) {
  const getBankName = useStore((state) => state.getBankName)

  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3}}>
      <span>
        {getBankName(transfer.source.bank)}-{transfer.source.cell}
      </span>
      <CellIcon cellCode={transfer.source.cell}/>
      <span style={{fontSize: 15}}>{' \u2192 '}</span>
      <span>
        {getBankName(transfer.destination.bank)}-{transfer.destination.cell}
      </span>
      <CellIcon cellCode={transfer.destination.cell}/>
    </div>
  )
}

export function PaymentRow(payment: PaymentWithContingencyAndUI): ReactElement {
  const {payment_id, date, amount, transfer, comment, isContingent} = payment;
  const encoded_value = Number(amount.value).toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return (
    <tr className={payment.focus ? css.FocussedPaymentRow : css.PaymentRow}>
      <td>
        {
          !!payment.locked_by ? (
            <Tooltip title={payment.locked_by?.user_email}>
              <LockIcon/>
            </Tooltip>
          ) : null
        }
        {
          isContingent ? (
            <Tooltip title={'Contingent'}>
              <PriorityHighIcon style={{color: '#af0000'}}/>
            </Tooltip>
          ) : null
        }
      </td>
      <td>
        {payment_id}
      </td>
      <td>
        <DateBadge date={date}/>
      </td>
      <td>
        <CurrencyFlag ccy={amount.currency}/>
        <b> {amount.currency}</b>
      </td>
      <td>
        {encoded_value}
      </td>
      <td>
        <TransferCell transfer={transfer}/>
      </td>
      <td>
        {comment}
      </td>
      <td>
        <MiniStepper payment={payment}/>
      </td>
      {
        payment.hideActions || (
          <td>
            <PaymentActionMenu payment={payment}/>
          </td>
        )
      }
    </tr>
  )
}

interface NettedPaymentViewerProps {
  show: boolean;
  hideActions?: boolean;
  onClose: Function;
  payments: Payment[];
  title: string;
}

export function NettedPaymentViewer({show, onClose, payments, title, hideActions}: NettedPaymentViewerProps) {
  return (
    <Modal title={title} show={show} onClose={onClose}>
      <div style={{paddingTop: 10, paddingBottom: 10}}>
        <PaymentTable paymentState={payments} hideActions={hideActions}/>
      </div>
    </Modal>
  )
}

export function NettedPaymentRow(payment: NettedPaymentWithContingencyAndUI): ReactElement {
  const [showViewer, setShowViewer] = useState(false);
  const allPayments = useStore((state) => state.payments)
  const {payment_id, date, amount, transfer, isContingent} = payment;
  const encoded_value = Number(amount.value).toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const relevantPayments = allPayments.filter((p): p is Payment =>
    p.payment_type === 'SINGLE' && payment.single_payment_ids.includes(p.payment_id))

  return (
    <tr className={payment.focus ? css.FocussedPaymentRow : css.PaymentRow}>
      <td>
        <Tooltip title={'Netted'}>
          <Grid4x4Icon/>
        </Tooltip>
        {
          isContingent ? (
            <Tooltip title={'Contingent'}>
              <PriorityHighIcon style={{color: '#af0000'}}/>
            </Tooltip>
          ) : null
        }
      </td>
      <td>
        {payment_id}
      </td>
      <td>
        <DateBadge date={date}/>
      </td>
      <td>
        <CurrencyFlag ccy={amount.currency}/>
        <b> {amount.currency}</b>
      </td>
      <td>
        {encoded_value}
      </td>
      <td>
        <TransferCell transfer={transfer}/>
      </td>
      <td>
        <Button onClick={() => setShowViewer(true)}>Show Netting</Button>
        <NettedPaymentViewer
          title={`Netted Payment: ${payment.payment_id}`}
          payments={relevantPayments}
          show={showViewer}
          onClose={() => setShowViewer(false)}
          hideActions={true}
        />
      </td>
      <td>
        <MiniStepper payment={payment}/>
      </td>
      <td>
        <PaymentActionMenu payment={payment}/>
      </td>
    </tr>
  )
}

export function NettedPaymentProposalRow(payment: NettedPaymentProposalWithContingencyAndUI): ReactElement {
  const {payment_ids, date, amount, transfer, isContingent} = payment;
  const encoded_value = Number(amount.value).toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const [showViewer, setShowViewer] = useState(false);
  return (
    <tr className={payment.focus ? css.FocussedPaymentRow : css.PaymentRow}>
      <td>
        {
          !!payment.locked_by ? (
            <Tooltip title={payment.locked_by?.user_email}>
              <LockIcon/>
            </Tooltip>
          ) : null
        }
        <Tooltip title={'Netted'}>
          <Grid4x4Icon/>
        </Tooltip>
        {
          isContingent ? (
            <Tooltip title={'Contingent'}>
              <PriorityHighIcon style={{color: '#af0000'}}/>
            </Tooltip>
          ) : null
        }
      </td>
      <td>
        {payment_ids.join(',')}
      </td>
      <td>
        <DateBadge date={date}/>
      </td>
      <td>
        <CurrencyFlag ccy={amount.currency}/>
        <b> {amount.currency}</b>
      </td>
      <td>
        {encoded_value}
      </td>
      <td>
        <TransferCell transfer={transfer}/>
      </td>
      <td>
        <Button onClick={() => setShowViewer(true)}>Show Netting</Button>
        <NettedPaymentViewer title={`Netting Proposal: ${payment.payment_ids.join(',')}`}
                             payments={payment.payments}
                             show={showViewer}
                             onClose={() => setShowViewer(false)}/>
      </td>
      <td>
        <MiniStepper payment={payment}/>
      </td>
      <td>
        <PaymentActionMenu payment={payment}/>
      </td>
    </tr>
  )
}
