import React, {ReactElement, useState} from "react";
import Modal from "../../../components/modal/modal";
import Button from "../../../components/button/button";
import {DatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/zh-cn';
import {PaymentService} from "../../../services/payments/payment-service";


const paymentService = new PaymentService()

interface DownloadPaymentsModalProps {
  show: boolean;
  onClose: Function;
}

export default function DownloadPaymentsModal(props: DownloadPaymentsModalProps): ReactElement {
  const {show, onClose} = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <Modal title={'Download Historical Payments'} show={show} onClose={onClose}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label="Start Date"
                    value={startDate}
                    format="YYYY-MM-DD"
                    onChange={(newValue) => setStartDate(newValue)}
        />
        <DatePicker label="End Date"
                    value={endDate}
                    format="YYYY-MM-DD"
                    onChange={(newValue) => setEndDate(newValue)}
        />
      </LocalizationProvider>

      <div style={{marginBottom: 15, display: 'flex', gap: 10, justifyContent: 'flex-end'}}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={startDate === null || endDate === null}
          onClick={async () => {
            await paymentService.downloadPayments(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
            onClose()
          }}
        >
          Download
        </Button>
      </div>
    </Modal>
  )
}