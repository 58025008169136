import React from "react";
import Button from "../../../components/button/button";
import {Menu} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {AnyPayment, useStore} from "../../../stores/app-store";
import {NettedPayment, NettedPaymentProposal, Payment} from "../../../types/schema";

// These variables can be rh or ts, both can be set to ts for testing purposes.
const TS_ORG_ID = process.env.REACT_APP_TS_ORG_ID || 'ts';
const RH_ORG_ID = process.env.REACT_APP_RH_ORG_ID || 'ts';

interface PaymentAction {
  title: string;
  action: Function;
  enabled_for: string[];
  forceDisabled?: boolean;
}

interface PaymentActions {
  [key: string]: PaymentAction[];
}

interface ActionMenuProps {
  payment: Payment | NettedPaymentProposal | NettedPayment;
}

function isPaymentValid(payment: AnyPayment) {
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  const d = new Date(payment.date);
  d.setHours(0, 0, 0, 0)
  return d >= now
}

export function PaymentActionMenu(props: ActionMenuProps) {
  if (isPaymentValid(props.payment)) return <ValidPaymentActionMenu {...props}/>
  else return <InvalidPaymentActionMenu {...props}/>
}

export function ValidPaymentActionMenu({payment}: ActionMenuProps) {
  const setPaymentDeletionProposal = useStore(state => state.setPaymentDeletionProposal);
  const requestEditPaymentLock = useStore(state => state.requestEditPaymentLock)
  const organisation = useStore(state => state.userData.organisation);
  const paymentService = useStore(state => state.paymentService);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const approvePaymentAction: PaymentAction = {
    title: "Approve",
    action: () => {
      paymentService.approvePayment((payment as Payment).payment_id);
      handleClose();
    },
    enabled_for: [TS_ORG_ID]
  };

  const editPaymentAction: PaymentAction = {
    title: "Edit",
    action: () => {
      if (payment.payment_type === 'NETTED_PROPOSAL') {
        // TODO: confirm what functionality is needed here
        console.log('editing NETTED_PROPOSAL', payment);
      } else if (payment.payment_type === 'NETTED') {
        // TODO: show an alert saying netted payment can be edited (maybe disable the button)
      } else {
        requestEditPaymentLock((payment as Payment).payment_id)
      }
      handleClose()
    },
    enabled_for: [TS_ORG_ID],
    forceDisabled: payment.payment_type === 'NETTED_PROPOSAL'
  };

  const deletePaymentAction: PaymentAction = {
    title: "Delete",
    action: () => {
      setPaymentDeletionProposal((payment as Payment).payment_id);
      handleClose();
    },
    // action: () => paymentService.deletePayment((payment as Payment).payment_id),
    enabled_for: [TS_ORG_ID],
    forceDisabled: payment.payment_type === 'NETTED_PROPOSAL'
  };

  const acknowledgePaymentAction: PaymentAction = {
    title: "Acknowledge",
    action: () => {
      if (payment.payment_type === 'NETTED_PROPOSAL') {
        paymentService.acknowledgeNettedPaymentProposal((payment as NettedPaymentProposal).payment_ids);
      } else {
        paymentService.acknowledgePayment((payment as Payment).payment_id);
      }
      handleClose();
    },
    enabled_for: [RH_ORG_ID]
  };

  const contestPaymentAction: PaymentAction = {
    title: "Contest",
    action: () => {
      if (payment.payment_type === 'SINGLE') {
        paymentService.contestPayment((payment as Payment).payment_id);
      } else if (payment.payment_type === 'NETTED') {
        paymentService.contestPayment((payment as NettedPayment).payment_id);
      }
      handleClose();
    },
    enabled_for: [RH_ORG_ID]
  };

  const confirmPaymentAction: PaymentAction = {
    title: "Confirm",
    action: () => {
      if (payment.payment_type === 'SINGLE') {
        paymentService.rhConfirmPayment((payment as Payment).payment_id);
      } else if (payment.payment_type === 'NETTED') {
        paymentService.rhConfirmPayment((payment as NettedPayment).payment_id);
      }
      handleClose();
    },
    enabled_for: [RH_ORG_ID]
  };

  const releasePaymentAction: PaymentAction = {
    title: "Release",
    action: () => {
      paymentService.releasePayment((payment as Payment).payment_id);
      handleClose();
    },
    enabled_for: [TS_ORG_ID]
  };

  const confirmReceiptPaymentAction: PaymentAction = {
    title: "Confirm Receipt",
    action: () => {
      paymentService.tsConfirmPayment((payment as Payment).payment_id);
      handleClose();
    },
    enabled_for: [TS_ORG_ID]
  };

  const actions: PaymentActions = {
    PAYMENT_ADDED: [
      approvePaymentAction,
      editPaymentAction,
      deletePaymentAction
    ],
    FIRST_APPROVAL: [
      approvePaymentAction,
      editPaymentAction,
      deletePaymentAction
    ],
    SECOND_APPROVAL: [
      acknowledgePaymentAction,
      editPaymentAction,
      deletePaymentAction
    ],
    RH_ACKNOWLEDGE: [
      confirmPaymentAction,
      contestPaymentAction,
    ],
    RH_CONFIRMED: [
      releasePaymentAction,
      contestPaymentAction
    ],
    PAYMENT_RELEASED: [
      confirmReceiptPaymentAction
    ],
    PAYMENT_CONFIRMED: [],
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={actions[payment.stage].length === 0}
      >
        Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          actions[payment.stage].map(a => (
            <MenuItem
              key={a.title}
              disabled={a.forceDisabled || !a.enabled_for.includes(organisation)}
              onClick={() => a.action()}
            >
              {a.title}
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}

export function InvalidPaymentActionMenu({payment}: ActionMenuProps) {
  const paymentService = useStore(state => state.paymentService);
  const organisation = useStore(state => state.userData.organisation);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          disabled={organisation === 'rh'}
          onClick={() => paymentService.recreateInvalidPayment((payment as Payment).payment_id)}
        >
          Recreate
        </MenuItem>
        <MenuItem
          disabled={organisation === 'rh'}
          onClick={() => {
            if (payment.payment_type === 'SINGLE') paymentService.deletePayment((payment as Payment).payment_id)
            else paymentService.deleteNettedPayment((payment as NettedPayment).payment_id)
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}
