import React, {ReactElement, useCallback, useState} from "react";
import {useLoaderData} from "react-router";
import Card from "../../components/card/card";
import {styled} from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TextField from "@mui/material/TextField/TextField";
import {ConfigService} from "../../services/config-service";
import {Cell, Currency} from "../../types/schema";
import Button from "../../components/button/button";
import {enqueueSnackbar} from "notistack";

const ListItem = styled('li')(({theme}) => ({
  margin: theme.spacing(0.5),
}));

type UpdateCallback = (tags: string[]) => void;

interface TagEditorProps {
  tags: string[];
  onUpdate: UpdateCallback;
  label: string;
}

function TagEditor({tags, onUpdate, label}: TagEditorProps) {
  const [inputValue, setInputValue] = useState('');

  const handleDelete = (tagToDelete: string) => () => {
    const filtered = tags.filter(t => t !== tagToDelete)
    onUpdate(filtered);
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter') {
      // Do code here
      setInputValue('');
      onUpdate([...tags, inputValue]);
      ev.preventDefault();
    }
  };

  return (
    <div>
      <TextField
        style={{width: '90%'}}
        label={label}
        variant="standard"
        helperText={`Enter a new ${label.toLowerCase()} and press enter.`}
        onChange={e => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        value={inputValue}
      />
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {tags.map((tag, i) => (
          <ListItem key={i}>
            <Chip
              label={tag}
              onDelete={handleDelete(tag)}
            />
          </ListItem>
        ))}
      </Paper>
    </div>
  );
}


const api = new ConfigService();

interface LoaderData {
  initCells: Cell[];
  initCurrencies: Currency[];
}

export async function loader(): Promise<LoaderData> {
  const [initCurrencies, initCells] = await Promise.all([
    api.getCurrencies(),
    api.getCells(),
  ]);
  return {initCurrencies, initCells};
}

export default function ConfigurationPage(): ReactElement {
  const {initCurrencies, initCells} = useLoaderData() as LoaderData;
  const [currencies, setCurrencies] = useState(initCurrencies.map(c => c.currency_code));
  const [cells, setCells] = useState(initCells.map(c => c.cell_code));
  const [isChanged, setIsChanged] = useState(false);

  const onUpdateCurrencies = useCallback((value: string[]) => {
    setCurrencies(value);
    setIsChanged(true);
  }, []);

  const onUpdateCells = useCallback((value: string[]) => {
    setCells(value);
    setIsChanged(true);
  }, []);

  const onSave = useCallback(async () => {
    await Promise.all([
      api.updateCells(cells.map(cell_code => ({cell_code}))),
      api.updateCurrencies(currencies.map(currency_code => ({currency_code}))),
    ])
    enqueueSnackbar(`Updated Successfully`, {variant: 'success'});
    setIsChanged(false);
  }, [currencies, cells]);

  const onReset = useCallback(() => {
    setCurrencies(initCurrencies.map(c => c.currency_code));
    setCells(initCells.map(c => c.cell_code));
    setIsChanged(false);
  }, [initCurrencies, initCells]);

  return (
    <div style={{display: 'flex', gap: 15, flexDirection: 'column'}}>
      {isChanged &&
          <Card title={'Save Changes'}>
              <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between'}}>
                  Changes have been made to the configuration:
                  <div style={{display: 'flex', gap: 5}}>
                      <Button onClick={onReset}>Reset</Button>
                      <Button onClick={onSave}>Save</Button>
                  </div>
              </div>
          </Card>
      }
      <Card title={'Currencies'}>
        <TagEditor
          tags={currencies}
          onUpdate={onUpdateCurrencies}
          label={'Currencies'}
        />
      </Card>
      <Card title={'Cells'}>
        <TagEditor
          tags={cells}
          onUpdate={onUpdateCells}
          label={'Cells'}
        />
      </Card>
    </div>
  );
}