import {ReadyState} from "react-use-websocket";
import {
  AcknowledgeNettedPaymentProposalEvent,
  AcknowledgePaymentEvent,
  AnyEvent, ApprovePaymentEvent, ContestPaymentEvent,
  CreatePaymentsEvent, DeleteNettedPaymentEvent,
  DeletePaymentEvent,
  LockPaymentEvent,
  Payment, RecreateInvalidPaymentEvent, ReleasePaymentEvent,
  RequestInitialStateEvent, RHConfirmPaymentEvent, TSConfirmPaymentEvent, UnlockPaymentEvent, UpdatePaymentsEvent
} from "../../types/schema";
import {SendJsonMessage} from "react-use-websocket/src/lib/types";
import {EventHandler} from "./event-handlers";
import * as eventHandlers from "./event-handlers";
import {RESTService} from "../rest";
import {PaymentOptions} from "../../stores/app-store";

interface EventHandlerMap {
  [key: string]: EventHandler;
}

// Build event handler map from eventType -> handler function
const eventHandlersMap: EventHandlerMap = Object.keys(eventHandlers)
  .reduce((acc: EventHandlerMap, handler) => {
    const eventName = handler.charAt(0).toUpperCase() + handler.slice(1).replace('Handler', '')
    acc[eventName] = eventHandlers[handler]
    return acc;
  }, {})

export class PaymentEventsService {
  public readyState: ReadyState;
  public sendJsonMessage: SendJsonMessage;
  public eventHandlersMap = eventHandlersMap;
  private readonly session_id: string;

  constructor(readyState: ReadyState, sendJsonMessage: SendJsonMessage, session_id: string) {
    this.session_id = session_id
    this.readyState = readyState
    this.sendJsonMessage = sendJsonMessage
  }

  private sendMessage(message: AnyEvent) {
    this.sendJsonMessage({...message, session_id: this.session_id});
  }

  public requestInitialState() {
    const requestInitialState: RequestInitialStateEvent = {
      event_type: "RequestInitialStateEvent"
    }
    this.sendMessage(requestInitialState);
  }

  public createPayments(payments: Payment[]) {
    const event: CreatePaymentsEvent = {event_type: 'CreatePaymentsEvent', payments};
    this.sendMessage(event);
  }

  public savePayments(payments: Payment[]) {
    const event: UpdatePaymentsEvent = {event_type: 'UpdatePaymentsEvent', payments};
    this.sendMessage(event);
  }

  public deletePayment(payment_id: number) {
    const event: DeletePaymentEvent = {event_type: 'DeletePaymentEvent', payment_id};
    this.sendMessage(event);
  }

  public deleteNettedPayment(payment_id: number) {
    const event: DeleteNettedPaymentEvent = {event_type: 'DeleteNettedPaymentEvent', payment_id};
    this.sendMessage(event);
  }

  public recreateInvalidPayment(payment_id: number) {
    const event: RecreateInvalidPaymentEvent = {event_type: 'RecreateInvalidPaymentEvent', payment_id};
    this.sendMessage(event);
  }

  public approvePayment(payment_id: number) {
    const event: ApprovePaymentEvent = {event_type: 'ApprovePaymentEvent', payment_id};
    this.sendMessage(event);
  }

  public acknowledgeNettedPaymentProposal(payment_ids: number[]) {
    const event: AcknowledgeNettedPaymentProposalEvent = {
      event_type: 'AcknowledgeNettedPaymentProposalEvent',
      payment_ids
    };
    this.sendMessage(event);
  }

  public acknowledgePayment(payment_id: number) {
    const event: AcknowledgePaymentEvent = {event_type: 'AcknowledgePaymentEvent', payment_id};
    this.sendMessage(event);
  }

  public rhConfirmPayment(payment_id: number) {
    const event: RHConfirmPaymentEvent = {event_type: 'RHConfirmPaymentEvent', payment_id};
    this.sendMessage(event);
  }

  public contestPayment(payment_id: number) {
    const event: ContestPaymentEvent = {event_type: 'ContestPaymentEvent', payment_id};
    this.sendMessage(event);
  }

  public releasePayment(payment_id: number) {
    const event: ReleasePaymentEvent = {event_type: 'ReleasePaymentEvent', payment_id};
    this.sendMessage(event);
  }

  public tsConfirmPayment(payment_id: number) {
    const event: TSConfirmPaymentEvent = {event_type: 'TSConfirmPaymentEvent', payment_id};
    this.sendMessage(event);
  }

  public requestPaymentLock(payment_ids: number[]) {
    const event: LockPaymentEvent = {event_type: 'LockPaymentEvent', payment_ids};
    this.sendMessage(event);
  }

  public releasePaymentLock(payment_ids: number[]) {
    const event: UnlockPaymentEvent = {event_type: 'UnlockPaymentEvent', payment_ids};
    this.sendMessage(event);
  }

}

export class PaymentService extends RESTService {
  async getPaymentMetadata(): Promise<PaymentOptions> {
    const result = await this.http.get('/payments/metadata')
    return await result.data;
  }

  async downloadPayments(start: string, end: string): Promise<void> {
    const qs = new URLSearchParams({start, end});
    const result = await this.http.get(`/payments/download?${qs}`)
    const csv = result.data;

    const link = document.createElement('a');
    link.href = 'data:text/csv;base64,' + btoa(csv);
    link.download = `payments_${start}_${end}.csv`;
    link.target = '_blank'
    link.click();
  }
}