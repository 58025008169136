import {create} from 'zustand'
import {
  BankConnectivityPair, BankDetails,
  CurrenciesEnum,
  InitialStateEvent, NettedPayment, NettedPaymentProposal,
  Payment,
  PaymentCategories,
  PaymentStages,
  PaymentTypes, TransferLocation, UserSession
} from '../types/schema'
import {PaymentEventsService} from "../services/payments/payment-service";
import {v4 as uuidv4} from "uuid";

export const stageUserPropertyMap = {
  FIRST_APPROVAL: 'ts_first_approved_by',
  SECOND_APPROVAL: 'ts_second_approved_by',
  RH_ACKNOWLEDGE: 'rh_acknowledged_by',
  RH_CONFIRMED: 'rh_confirmed_by',
  PAYMENT_RELEASED: 'ts_released_by',
  PAYMENT_CONFIRMED: 'ts_confirmed_by',
};

export type AnyPayment = NettedPayment | NettedPaymentProposal | Payment;

export const isContingent = (payment: AnyPayment, payments: AnyPayment[]) =>
  payments.some(p => {
    if (payment.payment_type === "NETTED_PROPOSAL") {
      return p.dependent_payment_ids?.some(payment_id => (payment as NettedPaymentProposal).payment_ids.includes(payment_id))
    } else {
      return p.dependent_payment_ids?.includes(payment.payment_id)
    }
  });


export function groupByContingency(payment: Payment, data: Payment[], acc: Payment[][]): Payment[][] {
  const contingents = data.filter(p =>
    payment.dependent_payment_ids && payment.dependent_payment_ids.includes(p.payment_id)
  );

  if (contingents.length > 0) {
    return [
      ...acc,
      [payment],
      contingents.map(contingent => groupByContingency(contingent, data, [])).flat().flat()
    ]
  } else return [...acc, [payment]];
}

export function findNonContingentPayments(paymentState: Payment[]): Payment[] {
  const contingentPaymentIds = paymentState
    .filter(({dependent_payment_ids}) => dependent_payment_ids)
    .map(({dependent_payment_ids}) => dependent_payment_ids)
    .flat();


  return paymentState.filter(p => {
    if (p.dependent_payment_ids) return true;
    else return !contingentPaymentIds.includes(p.payment_id);
  });
}

function findPaymentChain(payments: Payment[], payment_id: number): Payment[][] {
  const independentPayments = findNonContingentPayments(payments);
  const grouped = independentPayments.map(p =>
    groupByContingency(p, payments, []).reverse());
  return grouped.find(group =>
    group.some(stage =>
      stage.some(p => p.payment_id === payment_id)));
}

export type ValidatedField<T> = {
  value: T;
  validation?: string;
};

export interface EditablePayment {
  payment_id?: number;
  amount: ValidatedField<number>;
  source: ValidatedField<TransferLocation>;
  destination: ValidatedField<TransferLocation>;
  comment: ValidatedField<string>;
  allow_netting: ValidatedField<boolean>;
}

export interface PaymentEditor {
  date: string;
  currency: CurrenciesEnum;
  category: PaymentCategories;
  editablePayments: EditablePayment[][]
}

export interface PaymentWithContingency extends Payment {
  isContingent: boolean;
}

export interface NettedPaymentWithContingency extends NettedPayment {
  isContingent: boolean;
}

export interface NettedPaymentProposalWithContingency extends NettedPaymentProposal {
  isContingent: boolean
}

export type AnyPaymentWithContingency =
  PaymentWithContingency
  | NettedPaymentProposalWithContingency
  | NettedPaymentWithContingency


export interface UserData {
  email: string;
  name: string;
  image: string;
  organisation: "ts" | "rh";
}

export interface PaymentOptions {
  cells: string[];
  banks: BankDetails[];
  bankConnectivity: BankConnectivityPair[];
  currencies: CurrenciesEnum[];
  types: PaymentTypes[];
  categories: PaymentCategories[];
  stages: PaymentStages[];
}


type AppState = {
  payments: (Payment | NettedPayment)[],
  userData: UserData,
  paymentService: PaymentEventsService,
  paymentOptions: PaymentOptions,
  editingPayment?: PaymentEditor,
  errorState?: any,
  isInitialised: boolean;
  session_id: string;
  paymentDeletionProposal?: number;
}

// As the number of actions grows, the naming of functions here will become unmanageable.
// TODO: break the store down into smaller chunks
type AppActions = {
  setErrorState: (error: any) => void,
  setPaymentOptions: (paymentOptions: PaymentOptions) => void,
  setUserData: (userData: UserData) => void,
  setPaymentService: (paymentService: PaymentEventsService) => void,
  initialiseState: (initialState: InitialStateEvent) => void,
  getPaymentById: (payment_id: number) => (Payment | NettedPayment),
  createPayments: (payment: (Payment | NettedPayment)[]) => void,
  updatePayments: (payment: (Payment | NettedPayment)[]) => void,
  updatePaymentStage: (payment_id: number, stage: PaymentStages, user_email?: string | null) => void,
  deletePayment: (payment_id: number) => void,
  requestEditPaymentLock: (payment_id: number) => void,
  releaseEditPaymentLock: () => void,
  openEditPayment: (payment_id: number) => void,
  closeEditPayment: () => void,
  lockPayments: (payment_ids: number[], user_session: UserSession) => void,
  unlockPayments: (payment_ids: number[], user_session: UserSession) => void,
  getBankName: (bank_code: string) => string,
  setPaymentDeletionProposal: (payment_id: number) => void,
  clearPaymentDeletionProposal: () => void,
  addContingencyProperty: (payments: AnyPayment[]) => AnyPaymentWithContingency[],
}


export const useStore = create<AppState & AppActions>()((set, get) => ({
  session_id: uuidv4(), // session_id is set from frontend to persist across WS disconnect.
  setErrorState: (error: any) => set(state => ({...state, errorState: error})),
  paymentService: null,
  userData: null,
  isInitialised: false,
  payments: [],
  paymentOptions: null,
  editingPayment: null,
  errorState: null,
  paymentDeletionProposal: null,
  getPaymentById: (payment_id: number) => {
    const state = get();
    return state.payments.find(p => p.payment_id === payment_id)
  },
  setPaymentDeletionProposal: (payment_id: number) => set(state =>
    ({paymentDeletionProposal: payment_id})),
  clearPaymentDeletionProposal: () => set(state =>
    ({paymentDeletionProposal: null})),
  addContingencyProperty: (payments: AnyPayment[]) => {
    const state = get();

    const paymentRows: AnyPaymentWithContingency[] = payments.map(payment => ({
      ...payment,
      isContingent: isContingent(payment, state.payments),
    }));

    return paymentRows;
  },
  getBankName: (bank_code: string) => {
    if (bank_code === 'client') return 'Client';
    if (bank_code === 'other') return 'Other';
    const state = get();
    return state.paymentOptions.banks.find(b => b.bank_code === bank_code)?.name
  },
  setPaymentOptions: (paymentOptions: PaymentOptions) => set(() => ({paymentOptions})),
  setUserData: (userData: UserData) => set(() => ({userData})),
  setPaymentService: (paymentService: PaymentEventsService) => set(() => ({paymentService})),
  initialiseState: (initialState: InitialStateEvent) => set(() =>
    ({payments: initialState.payments, isInitialised: true})),
  createPayments: (newPayments: Payment[]) => set(state => ({
    payments: [...state.payments, ...newPayments]
  })),
  updatePayments: (payments: (Payment | NettedPayment)[]) => set(state => {
    const updated = state.payments.map(p => {
      const newPayment = payments.find(np => np.payment_id === p.payment_id);
      return newPayment || p;
    });

    return {payments: updated};
  }),
  updatePaymentStage: (payment_id: number, stage: PaymentStages, user_email?: string | null) => set(state => {
    const updated = state.payments.map(p => {
      if (p.payment_id === payment_id) {
        // Update the stage property
        p.stage = stage;

        // Update the relevant signature.
        const propertyKey = stageUserPropertyMap[stage];
        if (propertyKey && user_email !== undefined) p[propertyKey] = user_email
      }
      return p
    });

    return {payments: updated};

  }),
  deletePayment: (payment_id: number) =>
    set(state =>
      ({payments: state.payments.filter(p => p.payment_id !== payment_id)})),
  requestEditPaymentLock: (payment_id: number) => {
    const state = get();
    const singularPayments: Payment[] = getSingularPayments(state.payments);
    const paymentGroup = findPaymentChain(singularPayments, payment_id);
    const editingPaymentIds = paymentGroup.map(stage => stage.map(payment => payment.payment_id)).flat();
    state.paymentService.requestPaymentLock(editingPaymentIds);
    // TODO: add a spinner or something of the sort while the user waits
    return state
  },
  openEditPayment: (payment_id: number) => set(state => {
    const singularPayments: Payment[] = getSingularPayments(state.payments);
    const paymentGroup = findPaymentChain(singularPayments, payment_id)

    const editablePayments = paymentGroup.map(stage => stage.map(payment => {
      const editPayment: EditablePayment = {
        payment_id: payment.payment_id,
        amount: {
          value: payment.amount.value
        },
        source: {
          value: payment.transfer.source
        },
        destination: {
          value: payment.transfer.destination
        },
        comment: {
          value: payment.comment,
        },
        allow_netting: {
          value: payment.allow_netting,
        }
      };
      return editPayment
    }));

    return {
      ...state,
      editingPayment: {
        date: paymentGroup[0][0].date,
        category: paymentGroup[0][0].payment_category,
        currency: paymentGroup[0][0].amount.currency,
        editablePayments
      }
    }
  }),
  closeEditPayment: () => set(state => ({editingPayment: null})),
  releaseEditPaymentLock: () => {
    const state = get();
    const editingPaymentIds = state.editingPayment.editablePayments
      .map(stage => stage.map(payment => payment.payment_id)).flat();

    state.paymentService.releasePaymentLock(editingPaymentIds)
  },
  lockPayments: (payment_ids: number[], user_session: UserSession) => set(state => {
    const payments = state.payments.map(p => {
      if (payment_ids.includes(p.payment_id) && p.payment_type === 'SINGLE') {
        p.locked_by = user_session
      }
      return p;
    });
    return {payments: [...payments]}
  }),
  unlockPayments: (payment_ids: number[], user_session: UserSession) => set(state => {
    const payments = state.payments.map(p => {
      if (payment_ids.includes(p.payment_id) && p.payment_type === 'SINGLE') {
        delete p.locked_by;
      }
      return p;
    });
    return {payments: [...payments]}
  })
}));

export const getSingularPayments = (payments: (Payment | NettedPayment)[]) =>
  payments.filter((p): p is Payment => p.payment_type === 'SINGLE')

export type StoreType = typeof useStore;