import {Payment} from "../../../../types/schema";
import React, {Fragment, ReactElement} from "react";
import {useStore} from "../../../../stores/app-store";
import css from "./payment-table.module.scss"
import {Tooltip} from "@mui/material";
import {CondensedPaymentType, condensePayments, encodeNumber, DateBadge} from "./basic-payment-table";

function mapColours(stage: string) {
  if (stage.includes('RH')) return "#4F6FAC";
  else return "#3ca449"
}

export function CondensedPaymentTable({paymentState}: { paymentState: Payment[] }): ReactElement {
  const condensedPayments = condensePayments(paymentState);

  return (
    <table style={{width: '100%'}}>
      <thead>
      <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Amount</th>
        <th>Transfer</th>
        <th>Comment</th>
      </tr>
      </thead>
      <tbody>
      {
        condensedPayments.map(row => (
          <CondensedPaymentRow key={row.payment_id.join(',')} {...row}/>
        ))
      }
      </tbody>
    </table>
  )
}

export function CondensedPaymentRow(props: CondensedPaymentType): ReactElement {
  const {
    payment_id,
    date,
    amount,
    transfer,
    comment,
    stage
  } = props;
  const getBankName = useStore((state) => state.getBankName)


  return (
    <tr className={css.PaymentRow} style={{textAlign: 'center'}}>
      <td>
        {
          payment_id.flat().map(p_id => (
            <span key={p_id}>
              {p_id}
              <br/>
            </span>
          ))
        }
      </td>
      <td>
        <DateBadge date={date}/>
      </td>
      <td>
        <div style={{display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center'}}>
          <div><b>{amount[0][0].currency}</b></div>
          <div>
            {
              amount.flat().map(({value}, i) => (
                <span key={i}>{encodeNumber(value)}<br/></span>
              ))
            }
          </div>
        </div>
      </td>
      <td>
        <div style={{display: 'flex', alignItems: 'center', gap: 5, justifyContent: 'center'}}>
          {
            transfer.map((s, i) => (
              <Fragment key={i}>
                <div>
                  {
                    s.map((account, j) => (
                      <span key={j}>{getBankName(account)}<br/></span>
                    ))
                  }
                </div>
                {
                  i === transfer.length - 1 ? null : (
                    <div>
                      {s.map((_, j) => (
                        <span key={j} style={{fontSize: 15, cursor: 'pointer', color: mapColours(stage[i][j])}}>
                          <Tooltip title={stage[i][j]}>
                            <b>{'\u2192'}</b>
                          </Tooltip>
                          <br/>
                        </span>
                      ))}
                    </div>
                  )
                }
              </Fragment>
            ))
          }
        </div>
      </td>
      <td>
        {comment}
      </td>
    </tr>
  )
}