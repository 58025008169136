import React, {ReactElement, useState} from "react";
import Card from "../../components/card/card";
import Button from "../../components/button/button";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CreateAccountModal from "./components/create-account-modal";
import {PaymentOptions} from "../../stores/app-store";
import {useLoaderData} from "react-router";
import {AccountsService} from "../../services/accounts-service";
import {BankConnectivity, BankDetails, PaymentCategories} from "../../types/schema";
import {enqueueSnackbar} from "notistack";
import {PaymentService} from "../../services/payments/payment-service";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";


const paymentService = new PaymentService()
const accountsService = new AccountsService();

interface LoaderData {
  banks: BankDetails[],
  paymentOptions: PaymentOptions
}

export async function loader(): Promise<LoaderData> {
  const [banks, paymentOptions] = await Promise.all([
    accountsService.getBanks(),
    paymentService.getPaymentMetadata()
  ]);
  return {banks, paymentOptions};
}

type UpdateAccountCallback = (bank_code: string,
                              name: string,
                              currencies: string[],
                              cells: string[],
                              connectivity: BankConnectivity[],
                              isICC: boolean) => void;

const getBankName = (bankCode: string, paymentOptions: PaymentOptions) => paymentOptions.banks.find(b => b.bank_code === bankCode)?.name


interface BankConfigProps extends BankDetails {
  onUpdate: UpdateAccountCallback;
  paymentOptions: PaymentOptions;
}

function BankConfiguration(props: BankConfigProps): ReactElement {
  const {
    bank_code,
    name,
    currencies,
    is_icc,
    cells,
    connectivity,
    onUpdate,
    paymentOptions
  } = props;

  const currencyChange = (event: SelectChangeEvent<typeof currencies>) => {
    const {target: {value}} = event;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    onUpdate(bank_code, name, newValue, cells, connectivity, is_icc)
  };

  const cellChange = (event: SelectChangeEvent<typeof cells>) => {
    const {target: {value}} = event;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    onUpdate(bank_code, name, currencies, newValue, connectivity, is_icc)
  };

  const iccChange = (event: any) => {
    const newIsICC = event.target.checked;
    onUpdate(bank_code, name, currencies, cells, connectivity, newIsICC)
  }

  const connectivityChange = (destination_bank_code: string, event: SelectChangeEvent<typeof cells>) => {
    const {target: {value}} = event;
    const newValue = (typeof value === 'string' ? value.split(',') : value) as PaymentCategories[];
    const index = connectivity.findIndex(c => c.destination_bank_code === destination_bank_code);
    connectivity[index].payment_category = newValue;
    onUpdate(bank_code, name, currencies, cells, connectivity, is_icc)
  };

  return (
    <Card title={`${name} (${bank_code})`} defaultExpanded={false}>
      <div>
        <span>Basic Configuration</span>
        <hr/>

        <FormGroup>
          <FormControl sx={{m: 1, minWidth: 120, maxWidth: 700}}>
            <InputLabel>Currency</InputLabel>
            <Select
              value={currencies}
              label="Currency"
              onChange={currencyChange}
              multiple
            >
              {
                paymentOptions.currencies.map(c => (
                  <MenuItem key={c} value={c}>{c}</MenuItem>
                ))
              }
            </Select>
            <FormHelperText>Choose currencies this bank can hold.</FormHelperText>
          </FormControl>

          <FormControl sx={{m: 1, minWidth: 120, maxWidth: 700}}>
            <InputLabel>Cells</InputLabel>
            <Select
              value={cells}
              label="Cells"
              onChange={cellChange}
              multiple
            >
              {
                paymentOptions.cells.map(c => (
                  <MenuItem key={c} value={c}>{c}</MenuItem>
                ))
              }
            </Select>
            <FormHelperText>Select cells this bank holds money for.</FormHelperText>
          </FormControl>

          <FormControl sx={{m: 1, minWidth: 120}}>
            <FormControlLabel control={<Checkbox checked={is_icc} onChange={iccChange}/>} label="Is ICC?"/>
            <FormHelperText>Does this bank only contain a single account.</FormHelperText>
          </FormControl>

        </FormGroup>
      </div>

      <div style={{marginTop: 15}}>
        <span>Connectivity</span>
        <hr/>
        {
          connectivity
            .map(b => (
              <FormControl key={b.destination_bank_code} sx={{m: 1, minWidth: 120, maxWidth: 700}}>
                <InputLabel>{b.destination_bank_code}</InputLabel>
                <Select
                  value={b.payment_category}
                  label={b.destination_bank_code}
                  onChange={e => connectivityChange(b.destination_bank_code, e)}
                  multiple
                >
                  {
                    paymentOptions.categories.map(c => (
                      <MenuItem key={c} value={c}>{c}</MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>Can this account
                  pay {getBankName(b.destination_bank_code, paymentOptions)}.</FormHelperText>
              </FormControl>
            ))
        }
      </div>
    </Card>
  )
}

export default function AccountsPage(): ReactElement {
  const {paymentOptions, banks: initBanks} = useLoaderData() as LoaderData;
  const [bankConfigs, setBankConfigs] = useState([...initBanks]);
  const [showCreateBank, setShowCreateBank] = useState(false);
  const [isChanged, setIsChanged] = useState(false);


  const updateBanks = (bank_code: string,
                       name: string,
                       currencies: string[],
                       cells: string[],
                       connectivity: BankConnectivity[],
                       isICC: boolean) => {
    const index = bankConfigs.findIndex(b => b.bank_code === bank_code);
    bankConfigs[index] = {
      bank_code,
      name,
      currencies,
      cells,
      connectivity,
      is_icc: isICC
    };
    setBankConfigs([...bankConfigs]);
    setIsChanged(true);
  };

  const addBank = (bankName: string, bankCode: string) => {
    const newConfigs = bankConfigs.map(b => {
      b.connectivity.push({
        destination_bank_code: bankCode,
        payment_category: []
      })
      return b
    })
    newConfigs.push({
      bank_code: bankCode,
      name: bankName,
      currencies: [],
      cells: [],
      connectivity: bankConfigs.map(b => ({
        destination_bank_code: b.bank_code,
        payment_category: []
      }))
    });

    setBankConfigs(newConfigs);
    setIsChanged(true);
  };

  const saveChanges = async () => {
    await accountsService.updateBanks(bankConfigs);
    enqueueSnackbar(`Updated Successfully`, {variant: 'success'});
    setIsChanged(false);
  };

  const resetChanges = () => {
    setBankConfigs([...initBanks]);
    setIsChanged(false);
  };

  return (
    <div style={{display: 'flex', gap: 15, flexDirection: 'column'}}>
      {isChanged &&
          <Card title={'Save Changes'}>
              <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between'}}>
                  Changes have been made to the bank configuration:
                  <div style={{display: 'flex', gap: 5}}>
                      <Button onClick={resetChanges}>Reset</Button>
                      <Button onClick={saveChanges}>Save</Button>
                  </div>
              </div>
          </Card>
      }

      {
        bankConfigs.map(b => (
          <BankConfiguration key={b.bank_code} {...b} onUpdate={updateBanks} paymentOptions={paymentOptions}/>
        ))
      }
      <div style={{textAlign: 'center'}}>
        <Button onClick={() => setShowCreateBank(true)}>Add Bank</Button>
      </div>
      <CreateAccountModal
        show={showCreateBank}
        onClose={() => setShowCreateBank(false)}
        onCreate={addBank}
      />
    </div>
  );
}