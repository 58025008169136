import React from "react";
import {createBrowserRouter} from "react-router-dom";
import App from "./app";
import StatementsPage, {loader as statementsLoader} from "./pages/statements/statements";
import PaymentsPage, {loader as paymentsLoader} from "./pages/payments/payments";
import AccountsPage, {loader as accountsLoader} from "./pages/accounts/accounts";
import ConfigurationPage, {loader as configLoader} from "./pages/configuration/configuration";
import ErrorPage from "./pages/error/error";
import XeroPage, {loader as xeroLoader} from "./pages/xero/xero";


export const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children: [
      {path: '/payments', element: <PaymentsPage/>, loader: paymentsLoader},
      {path: '/accounts', element: <AccountsPage/>, loader: accountsLoader},
      {path: '/statements', element: <StatementsPage/>, loader: statementsLoader},
      {path: '/config', element: <ConfigurationPage/>, loader: configLoader},
      {path: '/xero', element: <XeroPage/>, loader: xeroLoader},
    ],
    errorElement: <ErrorPage/>
  }
]);