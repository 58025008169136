import {useStore, ValidatedField} from "../../../stores/app-store";
import {TransferLocation} from "../../../types/schema";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField/TextField";
import React from "react";

interface AccountSelectorProps {
  value: ValidatedField<TransferLocation>;
  onChange: any;
  disabled?: boolean;
}

const STATIC_BANKS = [
  {
    label: 'Client',
    value: 'client'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export function AccountSelector({value, onChange, disabled}: AccountSelectorProps) {
  const banks = useStore((state) => state.paymentOptions.banks)
  const cells = useStore((state) => state.paymentOptions.cells)

  return (
    <div>
      <FormControl error={!!value.validation}>
        <Select
          value={value.value.bank}
          onChange={e => onChange({bank: e.target.value, cell: value.value.cell})}
          disabled={disabled}
          error={!!value.validation}
        >
          {
            banks.map(o => <MenuItem key={o.bank_code} value={o.bank_code}>{o.name}</MenuItem>)
          }
          {
            STATIC_BANKS.map(sb => <MenuItem key={sb.value} value={sb.value}>{sb.label}</MenuItem>)
          }
        </Select>
        <FormHelperText>{value.validation}</FormHelperText>
      </FormControl>
      <FormControl error={!!value.validation}>
        {
          ['client', 'other', 'rbsi-tsmj'].includes(value.value.bank) ?
            <TextField
              style={{width: '90%', marginLeft: 5}}
              label="Investor Code"
              variant="standard"
              error={!!value.validation}
              helperText={value.validation}
              onChange={e => onChange({bank: value.value.bank, cell: e.target.value})}
              value={value.value.cell}
            /> :
            <>
              <Select
                value={value.value.cell}
                onChange={e => onChange({bank: value.value.bank, cell: e.target.value})}
                disabled={disabled}
                error={!!value.validation}
              >
                {
                  cells.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)
                }
              </Select>
              <FormHelperText>{value.validation}</FormHelperText>
            </>
        }
      </FormControl>
    </div>
  )
}