import React, {ReactElement, useState} from "react";
import css from "./edit-payment-modal.module.scss"
import Modal from "../../../components/modal/modal";
import Button from "../../../components/button/button";
import {
  Bank,
  BankConnectivityPair,
  CurrenciesEnum,
  Payment,
  PaymentCategories
} from "../../../types/schema";
import {
  EditablePayment,
  PaymentEditor,
  useStore,
  ValidatedField
} from "../../../stores/app-store";
import TextField from "@mui/material/TextField/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import 'dayjs/locale/zh-cn';
import useKeyboardShortcut from "use-keyboard-shortcut";
import {AccountSelector} from "./account-selector";

type AddPaymentCallback = (stageIndex: number) => void;
type DeletePaymentCallback = (stageIndex: number, paymentIndex: number) => void;
type UpdatePaymentCallback = (stageIndex: number, paymentIndex: number, data: EditablePayment) => void;
type AddDependentCallback = () => void;
type CloseModalCallback = () => void;

function deepCopy(obj: any): any {
  // TODO: move this to a utils files.
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}


interface EditPaymentRowProps {
  data: EditablePayment;
  canAddSibling?: boolean;
  canEditDestination?: boolean;
  canEditSource: boolean;
  onAddPayment?: AddPaymentCallback;
  onDeletePayment?: DeletePaymentCallback;
  onUpdatePayment?: UpdatePaymentCallback;
  stageIndex: number;
  paymentIndex: number;
}

function EditPaymentRow(props: EditPaymentRowProps) {
  const {
    data,
    canAddSibling = false,
    onAddPayment,
    stageIndex,
    paymentIndex,
    onDeletePayment,
    onUpdatePayment,
    canEditDestination,
    canEditSource
  } = props;
  const paymentUpdate = (diff: any) => {
    // For each changed property, assign the value of the record to the new value.
    Object.keys(diff).forEach(k => {
      data[k]['value'] = diff[k]; // Set the value
      data[k]['validation'] = undefined; // Reset validation so it can be recalculated at top level.
    });
    onUpdatePayment(stageIndex, paymentIndex, data)
  }
  const amountUpdate = (amount: string) => {
    amount = amount.replaceAll(',', '')
    const value = parseFloat(amount);
    // If NaN value is entered default to previous.
    if (amount === '') paymentUpdate({amount: undefined})
    else if (isNaN(value) || value.toString() !== amount) paymentUpdate({amount: amount});
    else paymentUpdate({amount: value});
  };
  return (
    <tr className={css.PaymentRow}>
      <td>
        <TextField
          style={{width: '90%'}}
          label="Amount"
          variant="standard"
          error={!!data.amount.validation}
          helperText={data.amount.validation}
          onChange={e => amountUpdate(e.target.value)}
          value={data.amount.value === undefined ? '' : data.amount.value}/>
      </td>
      <td>
        <AccountSelector
          value={data.source}
          onChange={(source: any) => paymentUpdate({source})}
          disabled={!canEditSource}
        />
      </td>
      <td>
        <AccountSelector
          value={data.destination}
          onChange={(destination: any) => paymentUpdate({destination})}
          disabled={!canEditDestination}
        />
      </td>
      <td style={{minWidth: 200}}>
        <TextField
          style={{width: '90%'}}
          label="Comment"
          variant="standard"
          error={!!data.comment.validation}
          helperText={data.comment.validation}
          onChange={e => paymentUpdate({comment: e.target.value})}
          value={data.comment.value || ""}
        />
      </td>
      <td>
        <input
          style={{cursor: "pointer"}}
          checked={data.allow_netting.value}
          type={'checkbox'}
          onChange={e => paymentUpdate({allow_netting: e.target.checked})}
        />
      </td>
      <td>
        <Button onClick={() => onDeletePayment(stageIndex, paymentIndex)}>Delete</Button>
      </td>
      <td>
        {canAddSibling ? <Button onClick={() => onAddPayment(stageIndex)}>Add Sibling</Button> : null}
      </td>
    </tr>
  )
}

interface EditPaymentStageProps {
  payments: EditablePayment[];
  canAddDependent: boolean;
  canAddContingent: boolean;
  canEditSource: boolean;
  onAddPayment?: AddPaymentCallback;
  onAddDependent?: AddDependentCallback;
  onAddContingent?: AddDependentCallback;
  onDeletePayment?: DeletePaymentCallback;
  onUpdatePayment?: UpdatePaymentCallback;
  stageIndex: number;
}

function EditPaymentStage(props: EditPaymentStageProps) {
  const {
    payments,
    canAddDependent = false,
    canAddContingent = false,
    canEditSource = true,
    onAddPayment,
    onDeletePayment,
    onAddDependent,
    onAddContingent,
    onUpdatePayment,
    stageIndex
  } = props;

  return <>
    {
      canAddContingent && (
        <div style={{textAlign: 'center', marginBottom: 10}}>
          <Button onClick={() => onAddContingent()}>Add Contingent</Button>
        </div>
      )
    }
    <div className={css.PaymentStage}>
      <table>
        <thead>
        <tr>
          <th>Amount</th>
          <th>From</th>
          <th>To</th>
          <th>Comment</th>
          <th>Netting</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          payments.map((row, i) =>
            <EditPaymentRow
              key={i}
              data={row}
              canAddSibling={payments.length === i + 1 && stageIndex === 0}
              canEditDestination={i === 0}
              canEditSource={canEditSource}
              onAddPayment={onAddPayment}
              onDeletePayment={onDeletePayment}
              onUpdatePayment={onUpdatePayment}
              stageIndex={stageIndex}
              paymentIndex={i}
            />
          )
        }
        </tbody>
      </table>
    </div>
    {
      canAddDependent && (
        <div style={{textAlign: 'center', marginBottom: 10}}>
          <Button onClick={() => onAddDependent()}>Add Dependent</Button>
        </div>
      )
    }
  </>
}

const validatePayments = (paymentStages: EditablePayment[][],
                          bankConnectivity: BankConnectivityPair[],
                          currentCategory: PaymentCategories,
                          allBanks: Bank[]) => {
  const bankMap = allBanks.reduce((acc, b) => Object.assign(acc, {[b.bank_code]: b.name}), {});
  return paymentStages.map((paymentStage, stageIndex) => paymentStage.map(payment => {
    if (payment.amount.value === undefined) payment.amount.validation = "Empty entry."
    else if (payment.amount.value === 0) payment.amount.validation = "Value cannot be 0."
    else if (isNaN(parseFloat(payment.amount.value.toString()))) payment.amount.validation = "Not a valid number."

    if (stageIndex !== 0) {
      const prevSum = paymentStages[stageIndex - 1].reduce((sum, p) => sum + p.amount.value, 0);
      const curSum = paymentStages[stageIndex].reduce((sum, p) => sum + p.amount.value, 0);

      if (prevSum !== curSum) payment.amount.validation = "Sum of amounts does not match."
      else payment.amount.validation = null
    }

    if (payment.comment.value === '') payment.comment.validation = "Comment cannot by empty."

    const connectivityRow = bankConnectivity.find(row =>
      row.source_bank_code === payment.source.value.bank &&
      row.destination_bank_code === payment.destination.value.bank
    )

    // First check if banks match
    if (
      payment.source.value.bank === payment.destination.value.bank &&
      payment.source.value.cell === payment.destination.value.cell
    ) {
      payment.source.validation = "Source and destination must differ.";
      payment.destination.validation = "Source and destination must differ.";
    } else if (connectivityRow && !connectivityRow.payment_category.includes(currentCategory)) {
      // If the banks don't match, but the category isn't allowed - error.
      payment.source.validation =
        `${bankMap[payment.source.value.bank]} is not allowed to pay ` +
        `${bankMap[payment.destination.value.bank]} for ${currentCategory}.`;

      payment.destination.validation = undefined;
    } else {
      // Else - all good.
      payment.source.validation = undefined;
      payment.destination.validation = undefined;
    }


    return payment
  }));
};

const isFormValid = (paymentStages: EditablePayment[][],
                     bankConnectivity: BankConnectivityPair[],
                     currentCategory: PaymentCategories,
                     allBanks: Bank[]) => {
  const validated = validatePayments(paymentStages, bankConnectivity, currentCategory, allBanks);
  let isValid = true;
  validated.forEach(stage =>
    stage.forEach(payment =>
      Object.values(payment).forEach((value: ValidatedField<any>) => {
        if (value.validation) isValid = false;
      })));
  return isValid;
};

interface EditPaymentModalProps {
  show: boolean;
  createMode: boolean;
  onClose: CloseModalCallback,
  initialConfig?: PaymentEditor
}

export default function EditPaymentModal(props: EditPaymentModalProps): ReactElement {
  const {
    show = false,
    onClose,
    createMode
  } = props;

  const banks = useStore((state) => state.paymentOptions.banks)
  const cells = useStore((state) => state.paymentOptions.cells)
  const currencies = useStore((state) => state.paymentOptions.currencies)
  const categories = useStore((state) => state.paymentOptions.categories)
  const bankConnectivity = useStore((state) => state.paymentOptions.bankConnectivity)
  const userEmail = useStore((state) => state.userData.email)
  const paymentService = useStore((state) => state.paymentService)

  const emptyPayment: EditablePayment = {
    amount: {value: 0},
    source: {value: {cell: cells[0], bank: banks[0].bank_code}},
    destination: {value: {cell: cells[0], bank: banks[0].bank_code}},
    comment: {value: ''},
    allow_netting: {value: true}
  };
  const defaultPaymentStages: EditablePayment[][] = props?.initialConfig?.editablePayments || [
    [deepCopy(emptyPayment)]
  ];

  const defaultCurrency = props.initialConfig?.currency || currencies[0];
  const defaultCategory = props.initialConfig?.category || categories[0];
  const defaultDate = dayjs(props.initialConfig?.date || new Date());
  const [valueDate, setValueDate] = useState(defaultDate);
  const [currency, setCurrency] = useState<CurrenciesEnum>(defaultCurrency);
  const [category, setCategory] = useState<PaymentCategories>(defaultCategory);

  const [paymentStages, setPaymentStages] = useState(validatePayments(defaultPaymentStages, bankConnectivity, category, banks));
  const isValid = isFormValid(paymentStages, bankConnectivity, category, banks);

  const addDependent = () => {
    const previousStage = paymentStages[paymentStages.length - 1];
    const defaultAmount = previousStage.reduce((sum, p) => sum + p.amount.value, 0);
    const defaultSource = previousStage[0].destination.value;

    const defaults = {
      amount: {value: defaultAmount},
      source: {value: defaultSource}
    }

    setPaymentStages([
      ...paymentStages,
      [deepCopy(Object.assign({}, emptyPayment, defaults))]
    ]);
  };
  const addContingent = () => {
    const defaultAmount = paymentStages[0].reduce((sum, p) => sum + p.amount.value, 0);
    setPaymentStages([
      [deepCopy(Object.assign({}, emptyPayment, {amount: {value: defaultAmount}}))],
      ...paymentStages
    ]);
  };
  const addPayment = (stageIndex: number) => {
    const defaultDestination = paymentStages[stageIndex].length === 0 ? 'RBSI' : paymentStages[stageIndex][0].destination;
    paymentStages[stageIndex].push(deepCopy(Object.assign({}, emptyPayment, {destination: defaultDestination})));
    setPaymentStages([...paymentStages]);
  };
  const deletePayment = (stageIndex: number, paymentIndex: number) => {
    if (paymentStages.reduce((sum, s) => sum + s.length, 0) === 1) return; // Don't allow deletion if there's only one row
    paymentStages[stageIndex].splice(paymentIndex, 1);
    if (paymentStages[stageIndex].length === 0) paymentStages.splice(stageIndex, 1);
    setPaymentStages([...paymentStages]);
  };
  const updatePayment = (stageIndex: number, paymentIndex: number, data: EditablePayment) => {
    if (paymentIndex === 0 && stageIndex === 0) {
      // Additional validation logic ensuring that all sibling payments share a single destination.
      paymentStages[stageIndex] = paymentStages[stageIndex]
        .map(p => Object.assign(p, {
          destination: {
            value: {...data['destination'].value}
          }
        }))

      // If there are dependent payments, update their source to match the contingent destination
      if (paymentStages.length > 1) {
        paymentStages[stageIndex + 1] = paymentStages[stageIndex + 1]
          .map(p => Object.assign(p, {
            source: {
              value: {...data['destination'].value}
            }
          }));
      }
    }

    paymentStages[stageIndex][paymentIndex] = data;
    setPaymentStages([...validatePayments(paymentStages, bankConnectivity, category, banks)]);
  };
  const closeAndReset = () => {
    onClose();
    setPaymentStages(defaultPaymentStages);
  };
  const createPayments = () => {
    let counter = 0;
    // Add temporary payment IDs as integers starting from 1.
    const payments = paymentStages
      .map(stage =>
        stage.map(p => {
          const payment: Payment = {
            payment_id: counter++,
            payment_category: category,
            payment_type: "SINGLE",
            date: valueDate.format('YYYY-MM-DD'),
            amount: {
              currency,
              value: parseFloat(p.amount.value.toString())
            },
            transfer: {
              source: p.source.value,
              destination: p.destination.value
            },
            comment: p.comment.value,
            stage: "FIRST_APPROVAL",
            dependent_payment_ids: null,
            allow_netting: p.allow_netting.value,
            locked_by: null,
            ts_first_approved_by: userEmail,
            ts_second_approved_by: null,
            rh_acknowledged_by: null,
            rh_confirmed_by: null,
            ts_released_by: null,
            ts_confirmed_by: null,
            metadata: {}
          }

          return payment;
        }));

    // Add dependent payment ids based on stages.
    const paymentsWithDependency = payments.map((stage, stageIndex) =>
      stage.map(p => {
        if (stageIndex !== 0) {
          return {...p, dependent_payment_ids: payments[stageIndex - 1].map((p: any) => p.payment_id)}
        } else return p
      })
    ).flat();

    paymentService.createPayments(paymentsWithDependency);
    closeAndReset();
  };
  const updatePayments = () => {
    let counter = -1;
    // Add temporary payment IDs as integers starting from -1 and moving negatively.
    // Negative IDs are used to denote new as opposed to edited payments
    // Note: we could actually use any unique symbol here, but the type is int so we go with negative numbers.
    const payments = paymentStages
      .map(stage =>
        stage.map(p => {
          let payment_id;
          if (p.payment_id) payment_id = p.payment_id
          else payment_id = counter--;

          const payment: Payment = {
            payment_id,
            payment_category: category,
            payment_type: "SINGLE",
            date: valueDate.format('YYYY-MM-DD'),
            amount: {
              currency,
              value: parseFloat(p.amount.value.toString())
            },
            transfer: {
              source: p.source.value,
              destination: p.destination.value
            },
            comment: p.comment.value,
            stage: "FIRST_APPROVAL",
            dependent_payment_ids: null,
            allow_netting: p.allow_netting.value,
            locked_by: null,
            ts_first_approved_by: userEmail,
            ts_second_approved_by: null,
            rh_acknowledged_by: null,
            rh_confirmed_by: null,
            ts_released_by: null,
            ts_confirmed_by: null,
            metadata: {}
          }

          return payment;
        }));

    const flattened = payments.map((stage, stageIndex) =>
      stage.map(p => {
        if (stageIndex !== 0) {
          return {...p, dependent_payment_ids: payments[stageIndex - 1].map((p: any) => p.payment_id)}
        } else return p
      })
    ).flat();

    paymentService.savePayments(flattened);
  };
  const onSubmit = createMode ? createPayments : updatePayments;

  useKeyboardShortcut(
    ["Shift", "Enter"],
    shortcutKeys => onSubmit(),
    {
      overrideSystem: true,
      ignoreInputFields: true,
      repeatOnHold: false
    }
  );

  useKeyboardShortcut(
    ["Escape"],
    shortcutKeys => onClose(),
    {
      overrideSystem: false,
      ignoreInputFields: true,
      repeatOnHold: false
    }
  );

  return (
    <Modal title={createMode ? 'Create Payment(s)' : 'Edit Payment(s)'} show={show} onClose={closeAndReset}>
      <div className={css.BasicSection}>
        <span>Basic</span>
        <hr/>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Date"
                        value={valueDate}
                        format="YYYY-MM-DD"
                        onChange={(newValue) => setValueDate(newValue)}
            />
          </LocalizationProvider>

          <FormControl>
            <Select
              value={currency}
              onChange={e => setCurrency(e.target.value as CurrenciesEnum)}
            >
              {
                currencies.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)
              }
            </Select>
            <FormHelperText>{'Currency'}</FormHelperText>
          </FormControl>
          <FormControl>
            <Select
              value={category}
              onChange={e => setCategory(e.target.value as PaymentCategories)}
            >
              {
                categories.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)
              }
            </Select>
            <FormHelperText>{'Payment Category'}</FormHelperText>
          </FormControl>
        </div>
      </div>

      <div>
        <span>Transfers</span>
        <hr/>
        <div>
          {
            paymentStages.map((stage, i) => (
              <EditPaymentStage
                key={i}
                stageIndex={i}
                payments={stage}
                canAddDependent={paymentStages.length === i + 1 && paymentStages.length < 2}
                canAddContingent={i === 0 && paymentStages.length < 2}
                canEditSource={i === 0}
                onAddPayment={addPayment}
                onUpdatePayment={updatePayment}
                onAddDependent={addDependent}
                onAddContingent={addContingent}
                onDeletePayment={deletePayment}
              />
            ))
          }
        </div>
      </div>

      <div style={{marginBottom: 15, display: 'flex', gap: 10, justifyContent: 'flex-end'}}>
        <Button onClick={() => closeAndReset()}>Cancel</Button>
        <Button disabled={!isValid} onClick={() => onSubmit()}>
          {createMode ? 'Create' : 'Save'}
        </Button>
      </div>
    </Modal>
  )
}