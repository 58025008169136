import React, {ReactElement, useEffect, useState} from "react";
import Card from "../../components/card/card"
import css from "./payments.module.scss"
import {netPayments, PaymentTable} from "./components/tables/basic-payment-table";
import {CondensedPaymentTable} from "./components/tables/condensed-payment-table";
import EditPaymentModal from "./components/edit-payment-modal";
import {LoaderPage} from "../../components/loader/loader";
import {getSingularPayments, PaymentOptions, useStore} from "../../stores/app-store";
import {PaymentService} from "../../services/payments/payment-service";
import {usePaymentService} from "../../hooks/payment-service-hook";
import {useLoaderData} from "react-router";
import {NettedPayment, NettedPaymentProposal, Payment} from "../../types/schema";
import DownloadPaymentsModal from "./components/download-payments-modal";
import useKeyboardShortcut from "use-keyboard-shortcut";
import PaymentControls, {defaultPaymentControlState, PaymentControlState} from "./components/payment-controls";
import DeletePaymentModal from "./components/delete-payment-model";

const paymentService = new PaymentService()

export function loader(): Promise<PaymentOptions> {
  return paymentService.getPaymentMetadata();
}

type AnyPayment = (Payment | NettedPayment | NettedPaymentProposal);

function selectAcknowledgedPayments(payments: AnyPayment[], paymentOptions: PaymentOptions) {
  const pastSecondApproval = payments.filter(p =>
    paymentOptions.stages.findIndex(s => s === p.stage) > paymentOptions.stages.findIndex(s => s === 'SECOND_APPROVAL'))

  const allNettedIds = pastSecondApproval.map(p => {
    if (p.payment_type === 'NETTED') return p.single_payment_ids;
    else return []
  }).flat()

  // Filter out the single payments that have been netted.
  return pastSecondApproval.filter(p =>
    !(p.payment_type === 'SINGLE' && allNettedIds.includes(p.payment_id)) && p.stage !== 'PAYMENT_CONFIRMED'
  );
}

function selectCompletedPayments(payments: AnyPayment[]) {
  return payments.filter(p => p.stage === 'PAYMENT_CONFIRMED')
}

function removeNettedConstituents(payments: (Payment | NettedPayment)[]) {
  const nettedPaymentIds = payments.filter(p => p.payment_type === "NETTED")
    .map(nettedPayment => (nettedPayment as NettedPayment).single_payment_ids).flat()
  return payments.filter(p => !(p.payment_type === 'SINGLE' && nettedPaymentIds.includes(p.payment_id)))
}

function removeSecondApproval(payments: (Payment | NettedPayment)[], paymentOptions: PaymentOptions) {
  const reachedSecondApproval = payments.filter(p =>
    paymentOptions.stages.findIndex(s => s === p.stage) >= paymentOptions.stages.findIndex(s => s === 'SECOND_APPROVAL') &&
    p.payment_type === 'SINGLE'
  ).map(p => (p as Payment).payment_id)

  return payments.filter(p => !(p.payment_type === 'SINGLE' && reachedSecondApproval.includes(p.payment_id)))
}

function selectSinglePayments(payments: (Payment | NettedPayment)[]): Payment[] {
  return payments.filter(p => p.payment_type === 'SINGLE') as Payment[];
}

function selectInvalidPayments(payments: (Payment | NettedPayment)[]) {
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  return payments.filter(p => {
    const d = new Date(p.date);
    d.setHours(0, 0, 0, 0)
    return d < now
  })
}

function selectValidPayments(payments: (Payment | NettedPayment)[], invalidPayments: AnyPayment[]) {
  return payments.filter(p => !invalidPayments.includes(p))
}

function selectPaymentsWithSearch(paymentState: (Payment | NettedPayment)[], controlState: PaymentControlState) {
  return paymentState.filter(p => {

    const idMatch = controlState.idSearch === '' || p.payment_id.toString().startsWith(controlState.idSearch);
    const dateMatch = controlState.dateSearch === '' || p.date.startsWith(controlState.dateSearch);
    const currencyMatch = controlState.currencySearch === '' || p.amount.currency.toLowerCase().startsWith(controlState.currencySearch.toLowerCase());
    const cellMatch =
      controlState.cellSearch === '' ||
      p.transfer.destination.cell.toLowerCase().startsWith(controlState.cellSearch.toLowerCase()) ||
      p.transfer.source.cell.toLowerCase().startsWith(controlState.cellSearch.toLowerCase());
    const bankMatch =
      controlState.bankSearch === '' ||
      p.transfer.destination.bank.toLowerCase().startsWith(controlState.bankSearch.toLowerCase()) ||
      p.transfer.source.bank.toLowerCase().startsWith(controlState.bankSearch.toLowerCase());
    const commentMatch = controlState.commentSearch === '' || p.comment.toLowerCase().startsWith(controlState.commentSearch.toLowerCase());

    return idMatch && dateMatch && currencyMatch && cellMatch && bankMatch && commentMatch;
  });
}

export default function PaymentsPage(): ReactElement {
  const [showDownload, setShowDownload] = useState(false);
  const [paymentControlState, setPaymentControlState] = useState<PaymentControlState>(defaultPaymentControlState);
  const [showAddPayment, setShowAddPayment] = useState(false);
  const paymentState = useStore((state) => state.payments)
  const isInitialised = useStore((state) => state.isInitialised)
  const setPaymentOptions = useStore((state) => state.setPaymentOptions)
  const editingPayment = useStore((state) => state.editingPayment)
  const releaseEditPaymentLock = useStore((state) => state.releaseEditPaymentLock)
  const paymentDeletionProposal = useStore((state) => state.paymentDeletionProposal)
  const paymentOptions = useLoaderData() as PaymentOptions;
  const [selectedRow, setSelectedRow] = useState(null);

  usePaymentService();

  const filteredPaymentState = selectPaymentsWithSearch(paymentState, paymentControlState);

  const invalidPayments = selectInvalidPayments(filteredPaymentState)
  const validPayments = selectValidPayments(filteredPaymentState, invalidPayments)
  const singularPayments = getSingularPayments(validPayments)
  const nettingProposals = netPayments(
    singularPayments.filter((p): p is Payment => p.stage === 'SECOND_APPROVAL'),
    paymentOptions
  );

  // The following variables are a 1-1 mapping with what is displayed on screen.
  // They are used to create the 'allVisiblePaymentIds' which allows for arrow selection.
  const condensedTablePayments = getSingularPayments(filteredPaymentState);
  const invalidTablePayments = removeNettedConstituents(invalidPayments);
  const todayTablePayments =
    removeSecondApproval(removeNettedConstituents(selectSinglePayments(validPayments)), paymentOptions);
  const approvedTablePayments = nettingProposals;
  const acknowledgedTablePayments = selectAcknowledgedPayments(validPayments, paymentOptions);
  const completedTablePayments = selectCompletedPayments(validPayments);

  const allVisiblePaymentIds = invalidTablePayments.map(p => [p.payment_id])
    .concat(todayTablePayments.map(p => [p.payment_id]))
    .concat(approvedTablePayments.map(p => p.payment_type === 'NETTED_PROPOSAL' ? p.payment_ids : [p.payment_id]))
    .concat(acknowledgedTablePayments.map(p => p.payment_type === 'NETTED_PROPOSAL' ? p.payment_ids : [p.payment_id]));

  const focussedPaymentId = selectedRow !== null ? allVisiblePaymentIds[selectedRow] : null;

  useEffect(() => setPaymentOptions(paymentOptions), [setPaymentOptions, paymentOptions]);

  useKeyboardShortcut(
    ["Shift", "A"],
    shortcutKeys => {
      if (editingPayment) return // Do nothing if a payment is being edited.
      else if (showAddPayment) setShowAddPayment(false)
      else setShowAddPayment(true)
    },
    {
      overrideSystem: true,
      ignoreInputFields: true,
      repeatOnHold: false
    }
  );

  useKeyboardShortcut(
    ["Shift", "ArrowUp"],
    shortcutKeys => {
      if (selectedRow === null) setSelectedRow(0);
      else if (selectedRow === 0) return;
      else setSelectedRow(selectedRow - 1);
    },
    {
      overrideSystem: true,
      ignoreInputFields: true,
      repeatOnHold: false
    }
  );

  useKeyboardShortcut(
    ["Shift", "ArrowDown"],
    shortcutKeys => {
      if (selectedRow === null) setSelectedRow(0);
      else if (selectedRow === allVisiblePaymentIds.length - 1) return;
      else setSelectedRow(selectedRow + 1);
    },
    {
      overrideSystem: true,
      ignoreInputFields: true,
      repeatOnHold: false
    }
  );

  useKeyboardShortcut(
    ["Escape"],
    shortcutKeys => setSelectedRow(null),
    {
      overrideSystem: true,
      ignoreInputFields: true,
      repeatOnHold: false
    }
  );

  if (!isInitialised) return <LoaderPage/>;

  return <div className={css.Container}>
    {
      showAddPayment && (
        <EditPaymentModal
          createMode={true}
          onClose={() => setShowAddPayment(false)}
          show={showAddPayment}
        />
      )
    }
    <DeletePaymentModal paymentDeletionProposal={paymentDeletionProposal} show={paymentDeletionProposal !== null}/>
    <DownloadPaymentsModal
      show={showDownload}
      onClose={() => setShowDownload(false)}
    />
    {
      // The following conditional inclusion is needed to force the editor to reload state from props.
      !editingPayment ? null : <EditPaymentModal
        createMode={false}
        onClose={() => releaseEditPaymentLock()}
        show={true}
        initialConfig={editingPayment}
      />
    }
    <PaymentControls
      state={paymentControlState}
      onFilterChange={newState => setPaymentControlState(newState)}
      onAddPayment={() => setShowAddPayment(true)}
      onDownload={() => setShowDownload(true)}
    />
    <div style={{width: '100%', height: 75}}/>
    <Card title={'Overview'} defaultExpanded={false}>
      <CondensedPaymentTable paymentState={condensedTablePayments}/>
    </Card>
    {
      invalidPayments.length > 0 && (
        <Card title={'Invalid Payments'}>
          <PaymentTable paymentState={invalidTablePayments} focussedPaymentId={focussedPaymentId}/>
        </Card>
      )
    }
    <Card title={'Today'}>
      <PaymentTable paymentState={todayTablePayments} focussedPaymentId={focussedPaymentId}/>
    </Card>

    <Card title={'Approved & Netted'}>
      <PaymentTable paymentState={approvedTablePayments} focussedPaymentId={focussedPaymentId}/>
    </Card>

    <Card title={'Acknowledged'}>
      <PaymentTable
        paymentState={acknowledgedTablePayments} focussedPaymentId={focussedPaymentId}
      />
    </Card>

    {
      completedTablePayments.length > 0 && (
        <Card title={'Complete'}>
          <PaymentTable
            paymentState={completedTablePayments} focussedPaymentId={focussedPaymentId}
          />
        </Card>
      )
    }
  </div>
}