import '@treasuryspring/spring-ui/dist/assets/main.css';
import "./styles/default/styles.scss";

import React from "react";
import {Auth0Provider} from '@auth0/auth0-react';
import {createRoot} from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import {router} from "./router";
import {datadogRum} from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '6a055017-bb3e-4471-b949-bedde414b6ec',
  clientToken: 'pub5be5a51cf3ea9691ec25f7c1a05cd9c0',
  site: 'datadoghq.eu',
  service: 'cash-register',
  env: process.env.ENV,
  // Commit SHA is passed as a version to help with bugs during deployment and caching issues.
  version: process.env.COMMIT_SHA,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  allowedTracingUrls: [(url) => url.endsWith("treasuryspring.com")]
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "openid profile email",
      }}
    >
      <RouterProvider router={router}/>
    </Auth0Provider>
  </React.StrictMode>
);

