import {RESTService} from "./rest";
import {BankDetails} from "../types/schema";

export class AccountsService extends RESTService {
  async getBanks(): Promise<BankDetails[]> {
    const result = await this.http.get("/accounts/banks")
    return result.data;
  }

  async updateBanks(bankConfig: BankDetails[]): Promise<void> {
    await this.http.put("/accounts/banks", bankConfig)
  }
}