import axios from 'axios';
import {useStore} from "../stores/app-store";

export interface Whitelisted {
  is_whitelisted: boolean;
}

export class RESTService {
  base_url: string = process.env.REACT_APP_BACKEND_URL;
  http = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
  });

  constructor() {
    this.http.interceptors.request.use(
      function (config) {
        //TODO: considering storing tokens as cookies (for this app it isn't an issue)
        config.headers.Authorization = `Bearer ${window.localStorage.getItem('accessToken')}`
        return config
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error)
      }
    )
    this.http.interceptors.response.use(
      response => response,
      error => {
        useStore.getState().setErrorState(error);
        throw error;
      }
    );
  }

  get accessToken() {
    return localStorage.getItem('accessToken');
  }

  get wsUrl() {
    return this.base_url
      .replace('http://', 'ws://')
      .replace('https://', 'wss://')
  }

  async checkWhitelist(): Promise<Whitelisted> {
    const result = await this.http.get("/whitelist/check")
    return result.data
  }
}